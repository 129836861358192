/* Раскладка */
.product {
  .action-print{
    font-weight: normal;
    // display: block;
    padding: 0 0 0 1em;
    text-decoration: none;
  }
  .product_left_block {
    float: left;
    position: relative;
    margin: 0 2em 0 0;
    width: calc(35% - 1em);
  }

  .product_mid_block {
    float: left;
    width: calc(65% - 1em);
  }

  .answers_questions{
    float: left;
    width: calc(30% - 1em);
    @include media(md) {
      display: none;
    }
    .module-inner{
      margin: 0;
      > .module-header {
        margin: 0;
        > span,
        > h2{
          font-size: 20px;
        }
      }
    }
    .editable_field {
      .faq {
        background: none;
        box-shadow: none;
        .news {
          .news_title{
            font-size: 15px;
            line-height: 22px;
            .icon {
              width: 30px;
              min-width: 30px;
              height: 30px;
              &:before {
                font-size: 16px;
              }
            }
          }
          .news_text {
            background: #efefef;
            font-size: inherit;
          }
        }
      }
    }
  }


  @include media(md) {

    .product_left_block {
      margin: 0 1em 0 0;
      width: calc(50% - 0.5em);
    }

    .product_mid_block {
      width: calc(50% - 0.5em);
    }
  }

  @include media(sm) {

    .product_left_block {
      margin: 0 0 1em;
      width: 100%;
    }

    .product_mid_block {
      width: 100%;
    }
  }

}


/* Оформление */
.product {
  // margin: 2em 0;

  .product_top {
    margin: 0 0 3em;
  }


  .product-header {
    position: relative;
  }

  .prod_title {
    display: inline-block;
    color: $prodTitleColor;
    // color: #000;
    font-size: 22px;
    font-weight: normal;
    margin: 0;
    padding: 0;
    text-transform: uppercase;
    @include media(xs) {
      font-size: 20px;
    }
  }

  .product_photo {
    &.carousel{
      @include media(xs) {
        // display: none;
      }
    }
    & > a {
      display: block;
      outline: none;

      & > img {
        display: block;
        height: auto;
        max-width: 100%;
        width: 100%;
        border-radius: $borderRadius;
      }
    }
  }


  &.--hidden {
    display: none;
  }


  .compare {
    text-align: left;
  }


  .compare_box {
    margin: 0 4px 0 0;
    padding: 0;
  }


  .short_description {
    // margin: 0.5em 0;

    * {
      padding: 0;
    }
    ul{
      list-style: none;
    }
  }

  .characters-table__row {
    color: $textColor;
    font-size: 14px;

    & > b {
      font-weight: normal;
    }
  }


  .characters-table {

    .characters-table__row {
      @extend .clearfix;

      color: $textColor;
      line-height: 1.75;
      position: relative;
      z-index: 1;

      a {
        color: $linkColor;

        &:hover {
          color: $linkColor--hover;
        }
      }

      & > b {
        float: left;
        padding: 0 1em 0 0;

        &:before {
          border-bottom: 1px dashed $borderColor;
          content: '';
          display: block;
          position: absolute;
          height: 1px;
          top: 62%;
          left: 0;
          right: 0;
          z-index: -1;
        }
      }

      & > span {
        float: right;
        padding: 0 0 0 1em;
      }


      & > span,
      & > b {
        background: $white;
        line-height: 1.75;
        z-index: 10;
      }

      & > b {
        font-weight: normal;
      }
    }
  }

  .prod_dop_option {

    & > b {
      // font-weight: normal;
    }

    &.rating_block {

      color: $grey;

      .rate_results {
        color: $textColor;
        font-size: 13px;
        float: right;
        padding: 5px 0 5px 10px;
      }

      & > b {
        color: $textColor;
        font-weight: normal;
        line-height: 1.75;
        position: relative;
        z-index: 10;
      }
    }
  }



  .product-prod_prices {
    position: relative;
    margin: 0.5em 0 0;
    @include media(md) {
      // text-align: right;
    }
  }

  .prod_big_price {
    display: inline-block;
    margin: 0 1em 0 0;

    & > span {
      font-size: 24px;
      // font-weight: bold;
    }
  }

  .prod_price_old {
    display: inline-block;

    & > span {
      color: $grey;
      font-size: 14px;
      text-decoration: line-through;
    }
  }


  @include media(md) {
    padding: 0;
    margin: 0;
  }

  .dop_atr{
    .prod_dop_option{
      padding: 0 0 8px;
      strong{
        color: #993300;
      }
      svg{
        display: inline-block;
        vertical-align: middle;
        margin-right: 0.2rem;
      }
    }
  }
}

.vat{
  // padding: 0 0.5em;
}

.buy_button_info{
  // display: flex;
  // flex-wrap: wrap;
  @include media(md) {
    // justify-content: flex-end;
  }
}


.kolvo_buy_button_add {
  display: inline-block;
  vertical-align: top;
  // position: absolute;
  // top: 0;
  // right: 1em;
  // margin: 0;

  @extend .clearfix;

  @include fontawesome('') {
    font-size: 21px;
  }

  .kolvo {
    float: left;
    margin: 0 1em 0 0;
    padding: 3px 0;
    width: 92px;
    user-select: none;
    border: 1px solid $linkColor--hover;
    background: $white;
    border-radius: $borderRadius;

    .input-text {
      display: inline-block;
      padding: 3px 0.25em 3px;
      text-align: center;
      vertical-align: middle;
      width: 34px;
      border-color: $linkColor--hover;
    }
  }

  .btn_add {
    float: left;
  }

  .kolvo_arrow {
    cursor: pointer;
    display: inline-block;
    text-align: center;
    height: 24px;
    width: 24px;

    &:hover {
      background: $white;
      transition: background 0.3s ease;
    }
  }
}



.fav-block {
  .catalog-item {
    float: left;
    margin: 0 2em 2em 0;
    width: calc(33.333% - 1.34em);


    &:nth-child(3n + 3) {
      margin: 0 0 2em;
    }
  }
}


.fav-link {
  display: inline-block;
  overflow: hidden;
  position: relative;
  height: 32px;
  width: 32px;
  text-indent: -9999px;
  vertical-align: text-bottom;

  @include fontawesome('\f004') {
    color: $textColor;
    text-indent: 0;
  }

  &.added {
    &:before {
      color: #d91c6b;
    }
  }

  &:hover {
    &:before {
      color: #d91c6b;
      transition: color 0.2s ease;
    }
  }
}



/* Ярлыки новинки/хит/скидка */

.product-labels {
  position: absolute;
  right: 1px;
  z-index: 2;

  & > span {
    color: #fff;
    cursor: default;
    display: block;
    font-size: 15px;
    margin: 0 0 4px;
    padding: 2px 6px;
    position: relative;
    height: 26px;
    text-align: center;
    user-select: none;
    &:hover{
      cursor: pointer;
    }

    &:before {
      content: '';
      width: 0;
      height: 0;
      border-top: 13px solid transparent;
      border-right: 11px solid transparent;
      border-bottom: 13px solid transparent;
      position: absolute;
      top: 0;
      left: -11px;
      bottom: 0;
    }
  }

  .label-new {
    display: none;
    background: #e54239;

    &:before {
      border-right-color: #e54239;
    }
  }

  .label-spec {
    background: #f19000;

    &:before {
      border-right-color: #f19000;
    }
  }

  .label-hit {
    background: #ffcd1f;
    color: #947711;

    &:before {
      border-right-color: #ffcd1f;
    }
  }
}


.product_dop_photo_slider {
  .thumb-group{
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    // justify-content: space-around;
    @include media(xs) {
    }
  }
  .dop-photo__thumb {
    border-radius: $borderRadius;
    cursor: pointer;
    overflow: hidden;
    border: 1px solid $borderColor;
    max-width: 95px;
    @include media(xs) {
      // display: none;
      max-width: 73px;
      border: none;
    }
    & > a {
      display: block;
    }

    & > img {
      display: block;
      width: 100%;
    }
  }


  & > .owl-carousel {
    padding: 0 1.4em;
    margin: 3em 0 0;
    @include media(xs) {
      padding: 0;
      margin: 0;
    }


    & > .owl-stage-outer {
      padding: 1px 0;
    }


    & > .owl-nav {
      perspective-origin: center;

      .owl-prev,
      .owl-next {
        color: $linkColor;
        font-size: 3em;
        height: 46px;
        opacity: 1;
        padding: 0;
        position: absolute;
        width: 15px;
        top: 0;

        &:hover {
          opacity: 0.5;
          transition: opacity 0.2s ease;
        }
      }

      .owl-prev {
        left: 0;
      }

      .owl-next {
        right: 0;
      }
    }
  }
}

/*ВКЛАДКИ*/
.product_dop_modes {
  position: relative;
  font-size: 14px;
  margin: 1em 0 2em;

  .product_dop_modes_navigation {
    padding: 0;
    position: relative;
    // height: 34px;
    z-index: 12;

    & > li {
      position: relative;
      display: block;
      float: left;
      font-size: 17px;
      color: $textColor;
      font-style: normal;
      // height: 35px;
      line-height: 34px;
      padding: 0 20px;
      margin-right: 5px;
      cursor: pointer;
      border-radius: $borderRadius $borderRadius 0 0;
      @include media(md) {
        font-size: 15px;
      }

      &:hover {
        color: $white;
        background: $linkColor--hover;
        transition: background 0.3s ease;
      }

      &.mod_selected {
        cursor: default;
        color: #fff;
        background: $linkColor;
      }
    }
  }

  .product_dop_modes_content {
    // border: 1px solid $borderColor;
    // border-bottom: 1px solid $borderColor;
    position: relative;
    z-index: 10;
    padding: 1em;
    text-align: justify;
    margin: 0 0 1em;
    border-radius: $borderRadius;
    &.scroll{
      overflow-x: auto;
      width: 100%;
      table{
        tr{
          td{
            padding:0.3em 0.5em;
          }
        }
      }
    }
    &.video{
      iframe{
        @include media(md) {
          width: 100%;
          height: auto;
        }
      }
    }
    li,
    p {
      color: #382507;
      font-size: 14px;
    }

    a {
      font-size: 14px;
    }

    .product_dop_title {
      font-size: 16px;
      font-weight: bold;
      padding-bottom: 1em;
    }
    @include media(xs) {
      table{
        tr{
          td{
            font-size: 12px;
            width: auto !important;
            padding: 0.3em  0.2em !important;
          }
        }
      }
    }
  }

}


/* Отзывы продукта */
.product-comments-form {
  @extend .clearfix;

  float: left;
  max-width: 300px;
  width: 100%;

  .form_title {
    position: static;
  }

  .form_block {
    margin: 0 0 1em;
  }

  .input-textarea,
  .input-text {
    display: block;
    padding: 3px 0.5em 3px;
    width: 100%;
  }

  .input-textarea {
    resize: none;
    height: 7em;
  }
}

.fast_order{
      display: inline-block;
    vertical-align: top;
    .button {
        background: #f36733;
    }
}

.fast_ordert_ok{
  .fast_ordert_ok{
    box-shadow: none;
  }
}

.fast_order_form--bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9990;
  display: none;
}


.prod-bnts {
  float: right;
  width: 40%;
}


.prod_selector {
  float: left;
  margin: 0 0 1.5em;
  width: 60%;

  .prod_selector_wrapper {
    display: inline-block;
    position: relative;

    &:after {
      background: #a8a8a8;
      content: '';
      height: 6px;
      width: 1px;
      top: 50%;
      margin-top: -3px;
      position: absolute;
      right: calc(1em + 3px);
      transform: rotate(-40deg);
    }

    &:before {
      background: #a8a8a8;
      content: '';
      height: 6px;
      width: 1px;
      top: 50%;
      margin-top: -3px;
      position: absolute;
      right: 1em;
      transform: rotate(40deg);
    }
  }

  .select_size {
    border: 1px solid #a8a8a8;
    border-radius: 0;
    color: #a8a8a8;
    cursor: pointer;
    min-width: 228px;
    outline: none;
    padding: 3px 1em;
    height: 33px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none !important;
  }


  .prod_selector_title {
    display: inline-block;
    color: #696969;
    font: normal 15px/1 "Arial", "Helvetica", sans-serif;
    margin: 0 5% 0 0;
  }
}

select::-ms-expand {
  display: none;
}


.prod_selector_title {
  color: $grey;
  font-style: normal;
  font-size: 17px;
}


.prod-color-wrapper {
  display: inline-block;
  margin: 0.5em 0 0.5em;

  .prod-color-title {
    display: block;
    font-size: 12px;
    font-style: normal;
    text-align: center;
    text-transform: lowercase;
  }
}


.responses_content {
  @extend .clearfix;

  .message_box {
    float: right;
    width: calc(100% - 300px);
    padding: 0 0 1em 1em;
    margin: 0 0 2em;
  }


  .message_top {
    border-bottom: 1px solid $borderColor;
    display: table;
    width: 100%;
    padding: 0 0 1em;
  }

  .message_text {
    font-size: 13px;
    margin: 0.5em 0 0;
  }


  .message_date,
  .message_name {
    display: table-cell;
  }


  .message_name {
    vertical-align: middle;

    & > span {
      font-size: 16px;
      font-weight: bold;
      vertical-align: middle;
    }
  }


  .message_date {
    float: right;
    text-align: center;

    & > span {
      font-size: 12px;
      padding: 1px 3px 2px;

      &.dt {
        background: $linkColor;
        color: #fff;
        display: block;
        border-radius: $borderRadius;
      }
    }
  }


  @include media(md) {

    .message_box {
      float: none;
      width: 100%;
    }
  }
}

.recommended-products,
.last-viewed-products{
  .short_description{
    display: none;
  }
}

.price-video{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @include media(lgs){
  }
  .price-block{
    width: 100%;
  }
  .video-block{
    overflow: hidden;
    max-width: 250px;
    width: 100%;
    max-height: 150px;
    @include media(lgs){
     margin: 1em 0 0;
     max-width: 100%;
     width: 100%;
     max-height: inherit;
     height: auto;
   }
   iframe{
    width: 250px;
    height: 150px;
    border: none;
    @include media(lgs){
     width: 100%;
     height: auto;
   }
 }
}
}

.mobile,
.tablet {
  .product_dop_photo{
    @include media(md){
    max-width: 100%;
    margin: 0 1px 0 auto;
    }
    img{
      max-width: 100%;
    }
  }
}

html{
  &.index{
    .product-labels {
      display: none;
    }
  }
}

.ya-share2{
  display: inline-block;
  vertical-align: middle;
}

.ya-share2__item_more.ya-share2__item_has-pretty-view .ya-share2__icon_more{
  background-image: url(../images/share.png) !important;
  background-size: auto !important;
}

.ya-share2__list.ya-share2__list_direction_horizontal {
  margin-top: -5px !important;
  padding: 0;
}

.product_left_block{
  .product-custom-labels{
    .label{
      span{
        font-size: 10px;
        display: block;
        width: 100%;
      }
    }
  }
}

.product-custom-labels{
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  .label{
    cursor: pointer;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    // overflow: hidden;
    max-width: 100px;
    // height: 100px;
    text-align: center;
    background: rgba(255,255,255,0.8);
    border-radius: 10px;
    margin: 0 0 10px;
    padding: 0.3px;
    box-shadow: 3px 4px 4px rgba(0,0,0,0.1);
    transition: background 0.3s ease;
          @include media(md) {
      padding: 5px;
      }
    &:hover{
      background: rgba(255,255,255,1);
    }
    img{
      display: block;
      margin: 0 auto;
    }
    span{
      color: $linkColor;
      font-weight: 700;
      text-transform: uppercase;
      @include media(md) {
        display: none;
      }
    }
  }
}

.specifications{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}