

.faq_gbook_block {
	margin-bottom: 20px;
	@include media(lg){
		padding: 0 0.5em;
	}
	.input-text,
	.input-textarea{
		height: 40px;
		width: 100%;
		max-width: 500px;
		@include media(lg){
			padding: 0.5em 0;
		}
	}
	.input-textarea{
		height: 100px;
	}
}

.message_box{							/*отзывы о товаре*/
	margin: 0 0 10px;
	padding: 10px;
	&:nth-child(2n + 2){
		background: #f0f0f0;
		border-radius: $borderRadius;
	}
}

.message_top {
	display: table;
	width: 100%;
	margin-bottom: 2px;
	border-bottom: inherit;
	padding: 2px 0;
}

.message_name, .message_date  {
	display: table-cell;
	vertical-align: top;
}

.message_name {
	font-weight: bold;
}

.message_date {
	width: 150px;
	font-weight: bold;
	text-align: right;
	padding: 0 5px;
	background: $white;
	border-radius: $borderRadius;
}
.message_date > span{
	color: #999;
	margin-right: 10px;
}

.message_text{
	color: inherit;
	padding: 5px;
}
