@import "../system/icon";

header{
	@extend .animated;
	@include media(md) {
		background: none;
	}
	&.scrolled{
		// background: url(../images/fon.jpg) center 0 no-repeat;
		background: $white;
		z-index: 100;
		width: 100%;
		top: 0;
		padding: 0;
		&.active{
			@extend .fadeInDown;
			position: fixed;
		}
		.header-bottom{
			padding: 0 0 1em;
			> .container{
				height: auto;
				// background: url(../images/header2.png) -40px 0 no-repeat;
				background: none;
				@include media(lg){
				}
			}
			.header-contacts{
				width: 55%;
				background: none;
				height: auto;
				margin: 1em 0 0;
				@include media(lg){
					width: 80%;
				}
				@include media(md){
					width: 100%;
					padding: 0 1em 1em;
					margin: 0;
				}
			}
			.logo{
				width: 33%;
				text-align: left;
				@include media(md){
					width: 100%;
					text-align: center;
				}
				.logo-img{
					display: none;
					max-width: 360px;
					max-height: 91px;
					&.fixed{
						padding: 0.3em 0 0;
						display: block;
					}
				}
				.slogan{
					font-size: 16px;
					padding: 0.4em 0 0 1em;
				}
			}
			.phones{
				width: 33.333%;
				float: left;
				padding: 0 1em 0 2em;
				margin: 0;
				.phone-title{
					display: none;
				}
				.icon{
					margin: -12px 0 0;
				}
			}
			.phones-list__item{
				> a{
					line-height: 30px;
					text-shadow: white 1px 1px 0, white -1px -1px 0, white -1px 1px 0, white 1px -1px 0;
				}
			}
			.mail{
				width: 66.66%;
				float: right;
				padding: .8em 1em 0;
				@extend .clearfix;
				@include media(xmd){
					padding: .5em 0 0;
				}
				.mail-wrapper{
					float: right;
					margin: -3px 0 0;
					@include media(sm){
						display: block;
						float: none;
					}
					.mail-link{
						text-shadow: white 1px 1px 0, white -1px -1px 0, white -1px 1px 0, white 1px -1px 0;
					}
				}
			}
			.call-order-wrapper{
				float: left;
				padding: 0 0 0 27px;
				margin: 0 2em 0 0;
				text-align: left;
				@include media(md){
					margin: 0;
				}
				@include media(sm){
					display: block;
					float: none;
					margin: 1em 0 0 0;
				}
				.call-order{
					text-shadow: white 1px 1px 0, white -1px -1px 0, white -1px 1px 0, white 1px -1px 0;
				}
				.call-time{
					// display: none;
				}
				.icon{
					margin: -26px 0 0 0;
				}
			}
			.times-block{
				.title{
					display: none;
				}
			}
			.header-cart{
				// padding: 0.3em 0 0;
				// width: 14%;
				padding: 0;
				width: 10%;
				@include media(md){
					float: none;
					width: 15%;
					min-width: 120px;
					position: absolute;
					top: 0;
					right: 0;
				}
				@include media(sm){
					top: 46px;
				}
				.cart{
					// padding: 1em 0 0 3em;
					padding: 0;
					> .header-cart__title{
						display: none;
					}
					&:before{
						top: 15px;
						left: 0;
						// background: url(../images/cart_icon-fixed.png) -5px -5px no-repeat;
						// width: 61px;
						// height: 88px;
						width: 32px;
						height: 32px;
						background: url(../images/cart.png) 0 0 no-repeat;
						background-size: contain;
					}
					&.full{
						&:before {
							// background: url(../images/cart_icon-fixed.png) -77px -4px no-repeat;
							// height: 68px;
						}
					}
					>.count{
						padding: 10px 0 0 20px;
						>.count-quantity{
							font-size: 15px;
							font-weight: 900;
							width: 29px;
							height: 29px;
							border-width: 1px;
							line-height: 29px;
						}
					}
				}
			}
			.call-order{
				&.button{
					font-size: 12px;
					padding: 5px 14px;
				}
			}
		}
		nav{
			.menu_level_1{
				> li{
					> a{
						font-size: 14px;
						padding: 0.5em;
					}
				}
			}
			.search{
				margin: 0;
				.search-form{
					margin: 4px 0;
				}
			}
		}
		.header-cart{
			.cart {
				.summ-block{
					// margin: -11px 0 0 -43px;
					margin: 0;
					padding: 0 10px 0 0;
					.header_cart-goods-quantity{
						transform: rotate(0);
					}
				}
			}
		}
	}
}

#progressBar{
	height: 3px;
	background: $linkColor;
	top: 0;
	left: 0;
	right: auto;
	bottom: auto;
	position: fixed;
	z-index: 9999;
	pointer-events: none;
}

.header-bottom {
	> .container{
		height: 160px;
		background: $headerTopBgColor;
		@include media(lg){
			height: auto;
			background: none;
		}
	}
	.logo {
		float: $logoPos;
		width: $logoWidth;
		margin: $logoMargin;
		padding: $logoPadding;
		text-align: right;
		@extend .clearfix;
		@include media(lgs){
			width: 50%;
		}
		a{
			display: block;
			@extend .clearfix;
		}
		.logo-img{
			width: $logoImgwidth;
			max-width: $logoImgmaxWidth;
			height: $logoImgheight;
			max-height: $logoImgmaxHeight;
			vertical-align: middle;
			margin: 0 2.5em 0 0;
			@include media(lgs){
				width: 100%;
			}
			@include media(lg){
				max-width: 315px;
			}
			@include media(md) {
				float: none;
			}
			&.fixed{
				display: none;
			}
		}
		.slogan{
			font-size: 20px;
			font-weight: 900;
			float: left;
			color: $textColor;
			text-align: center;
			padding: 0.8em 0 0 1em;
			text-transform: uppercase;
		}
	}
	.header-contacts {
		float: $heaContFloat;
		padding: $heaContPadding;
		width: $heaContWidth;
		@extend .clearfix;
		background: url(../images/fon_contacts2.png) 0 -23px no-repeat;
		height: 160px;
		@include media(lgs){
			width: 25%;
		}
		@include media(lg){
			background: none;
			width: 70%;
			padding: 0 1em;
			height: inherit;
		}
		@include media(xmd){
			width: 100%;
		}
	}
	.phones {
		padding: 0 1em 0 4em;
		margin: 1.3em 0 0;
		@include media(lg){
			float: left;
			padding: 0 0 0 3em;
			margin: 0;
			width: 40%;
		}
		.phone-title{
			padding: 0 0 0 1.5em;
			@include media(xmd){
				display: none;
			}
		}
	}
	.mail {
		padding: 0 0 0 2.5em;
		@include media(lg){
			float: left;
			padding: 0.3em 0 0;
			width: 60%;
			&:before,
			&:after {
				content: " ";
				display: table;
			}
			&:after {
				clear: both;
			}
		}
		@include media(sm){
			width: 100%;
			padding: 0;
		}
		.mail-wrapper {
			padding: 0 0 0 2em;
		}
		.call-order-wrapper{
			position: relative;
			text-align: center;
			padding: 0 2.5em .4em 0;
			@include media(lg){
				float: right;
				display: inline-block;
				padding: 0 0 0 0.5em;
			}
			@include media(sm){
				float: none;
				display: inline-block;
			}
			.icon{
				margin: -28px 0 0 0;
				@include fontawesome('\f2a0') {
					font-size: 21px;
					transform: rotate(-55deg);
					margin: -16px 0 0 -6px;
				}
			}
		}
	}
	.times-block {
		float: left;
		padding: 0 1em;
		width: 33.333%;
	}
	.header-cart {
		float: right;
		width: 14%;
		@include media(lgs){
			width: 20%;
		}
		@include media(lg){
			width: 30%;
		}
		@include media(xmd){
			width: 100%;
			padding: .5em 0;
		}
	}
	.auth-block {
		.auth-box {
			float: left;
			padding: 10px 1em 0;
			.reg {
				margin-right: 10px;
			}
			.enter {
			}
		}
	}
	@include media(lg) {
		.logo {
			text-align: center;
			width: 100%;
		}
	}
	@include media(xmd) {
		.logo {
			float: none;
			text-align: center;
			width: 100%;
			& > a {
				display: block;
			}
			.logo-img{
				display: inline-block;
				width: 100%;
			}
			.slogan{
				display: inline-block;
				vertical-align: middle;
				float: none;
			}
		}
	}
}
/* конец раскладки */
.header-bottom {
	& > .container {
		position: relative;
	}
	.phones {
		position: relative;
		.icon {
			margin: -10px 0 0 2.5em;
			@include media(lg){
				margin: -10px 0 0 1em;
			}
			@include media(sm) {
				margin: -12px 0 0;
			}
			@include fontawesome('\f095') {
				color: $textColor;
			}
		}
	}
	.phones-list {
		display: inline-block;
		list-style: none;
		margin: 0;
		position: relative;
		vertical-align: middle;
		padding: .5em 0 0;
	}
	.phones-list__item {
		& > a {
			color: $textColor;
			display: block;
			font-size: 19px;
			font-weight: bold;
			text-decoration: none;
			white-space: nowrap;
		}
		.pref {
			font-weight: normal;
		}
	}
	.mail {
		.mail-wrapper {
			position: relative;
			margin: 0 0 1em;
			.icon {
				@include fontawesome('\f0e0') {
					color: $iconColor;
					margin: -12px 0 0 -10px;
				}
			}
			@include media(lg) {
				float: left;
				display: inline-block;
				vertical-align: top;
				margin: 0 0 0.5em;
			}
			@include media(sm) {
				padding: 0 0 0 3em;
			}
		}
	}
	.mail-link {
		font-size: 15px;
		line-height: 1;
		vertical-align: middle;
		white-space: nowrap;
	}
	.call-order {
		white-space: nowrap;
		color: #B34721;
		cursor: pointer;
		text-decoration: underline;
		&:hover{
			color: #7D5301;
		}
		&.button{
			&:active {
			}
		}
	}
	.times-block {
		.title {
			font-size: 15px;
			line-height: 1;
			margin: 0 0 3px;
			text-transform: uppercase;
			@include media(md) {
				padding: 0 0 0 2em;
			}
		}
	}
	.times {
		padding: 0 0 0 1.5em;
		position: relative;
		white-space: nowrap;
		@include media(md) {
			padding: 0 0 0 2em;
		}
		& > span {
			font-weight: 900;
		}
		@include fontawesome('\f017') {
			color: $iconColor;
			left: 0;
			margin: -12px 0 0 0;
			@include media(md) {
				margin: -25px 0 0;
			}
		}
	}
}
.header-cart {
	cursor: default;
	text-align: right;
	user-select: none;
	@include media(lg){
	}
	&.open {
		.cart {
			&.full {
				&:before {
					color: $iconColor;
				}
			}
		}
	}
	.cart {
		display: inline-block;
		position: relative;
		white-space: nowrap;
		width: 100%;
		padding: 1em 0 0 7em;
		@include media(lg){
			padding: 0.5em 0 0 11em;
		}
		@include media(xmd){
			padding: 0 0 0 11em;
		}
		@include fontawesome('') {
			margin: 0;
			top: 0;
			left: 16px;
			// background: url(../images/cart_icon.png) -10px 39px no-repeat;
			width: 139px;
			height: 160px;
			@include media(lg){
				// background: url(../images/cart_icon.png) -10px -122px no-repeat;
				// width: 139px;
				// height: 40px;
			}
			@include media(xmd){
				// top: 0;
				// background: url(../images/cart_icon.png) -10px -122px no-repeat;
			}
		}
		&.full{
			&:before {
				// background: url(../images/cart_icon.png) -176px -5px no-repeat;
				@include media(lg){
					// background: url(../images/cart_icon.png) -10px -122px no-repeat;
				}
			}
		}
		& > .header-cart__title-mob {
			display: none;
		}
		& > .header-cart__title {
			display: none;
			font-size: 15px;
			font-weight: 900;
			color: $linkColor;
			line-height: 1;
			text-transform: uppercase;
			text-shadow:
			1px  1px   	  #fff,
			-1px  1px     #fff,
			1px -1px   	  #fff,
			-1px -1px     #fff,
			1px  1px 5px #555;
		}
		.summ-block {
			// display: none;
			padding: 0;
			position: relative;
			z-index: 3;
			margin: 93px -17px 0 -93px;
			line-height: 1;
			@include media(lgs){
				margin: 93px 5px 0 0;
			}
			@include media(lg){
				margin: 5px 0 0;
				text-align: left;
			}
			.header_cart-goods-text{
				display: none;
			}
			.header_cart-goods-quantity{
				font-size: 14px;
				font-weight: bold;
				display: block;
				text-align: right;
				@include media(lg){
					// text-align: left;
				}
				.currency{

				}
			}
		}
		& > .count {
			visibility: hidden;
			position: relative;
			z-index: 3;
			& > .count-text {
				display: none;
			}
			& > .count-quantity {
				display: block;
				width: 39px;
				height: 37px;
				border: 3px solid #E74005;
				border-radius: 100%;
				background: $white;
				font-size: 17px;
				line-height: 32px;
				padding: 0;
				text-align: center;
				@include media(lg){
					font-size: 19px;
					line-height: 34px;
					width: 39px;
					height: 37px;
				}
				@include media(xmd){
					font-size: 19px;
					font-weight: 900;
					width: 39px;
					height: 37px;
					border-width: 2px;
					line-height: 33px;
				}
			}
			& > .qt{
				display: none;
			}
		}
	}
}
.desktop {
	.header-cart {
		.cart {
			&.full {
				cursor: pointer;
			}
			&:not(.empty) {
				&:hover {
					&:before {
						color: $linkColor;
					}
				}
			}
		}
	}
}
