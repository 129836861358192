/* Стили карточки в каталоге */
.catalog-list{
  &.structure{
    .catalog-item{
      padding: 0.25em 1em;
      .catalog-item__title{
        display: block;
        font-weight: 700;
        text-transform: uppercase;
        text-decoration: none;
        color: $linkColor;
      }
    }
  }
}
.catalog-item {
  padding: 0.25em 1em 6em 1em;
  position: relative;
  border-radius: $borderRadius;
  overflow: hidden;
  &:hover{
    .product_photo {
      .show_product{
        .photo_hover{
          opacity: 1;
          @extend .animated;
          @extend .flipInY;
        }
      }
    }
  }
  .fav-link {
    position: absolute;
    top: 0;
    right: 0;

    &:before {
      color: $grey;
      font-size: 15px;
      margin: -11px 0 0 -7px;
    }

    &:hover {
      &:before {
        color: $linkColor;
      }
    }
  }

  .product_photo {

    & > .show_product {
      display: block;
      position: relative;
      @include media(xmd) {
        max-width: 215px;
        margin: 0 auto;
      }
      & > img {
        display: block;
        height: 100%;
        max-width: 100%;
        margin: 0 auto;
      }
      .photo_hover{
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
      }
    }
  }


  .catalog-item__title {
    padding: 0.5em 0;
    text-align: center;

    & > .show_product {
      display: block;
      font-weight: 700;
      text-transform: uppercase;
      text-decoration: none;
    }
  }

  .short_description {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    // flex-wrap: wrap;
    // flex: 1 1 100%;
    // display: block;
    font-size: 12px;
    text-transform: lowercase;
    ul{
      list-style: none;
      padding: 0;
    }
    svg{
      margin-right: 0.2em;
      &:not(:first-child){
        margin-left: 1em;
      }
    }
  }


  .catalog-item__bottom {
    position: absolute;
    left: 1em;
    right: 1em;
    bottom: 1em;
    text-align: center;
    @include media(xs) {
      left: 0;
      right: 0;
    }
  }


  .catalog-item__price-block {
    @extend .clearfix;
  }

  &.w-old{
    .prod_price_old {
      width: 50%;
    }
    .prod_big_price {
      width: 50%;
    }
  }


  .prod_price_old {
    float: left;
    text-align: center;
    line-height: 24px;
    & > span {
      color: #8f8f8f;
      font-size: 17px;
      text-decoration: line-through;
    }
  }

  .prod_big_price {
    float: left;
    width: 100%;
    text-align: center;
    line-height: 24px;
    & > span {
      color: #000;
      font-size: 24px;
      @include media(xmd) {
        font-size: 18px;
      }
      &.currency {
        font-size: 19px;
      }
    }
  }


  .kolvo {
    background: $white;
    float: none;
    margin: 0;
    padding: 3px 0;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    text-align: center;
    visibility: hidden;
    width: auto;
    z-index: 1;
    cursor: default;
    border-radius: $borderRadius;
  }


  .kolvo_buy_button_add {
    margin: 0;
    .composition{
      float: left;
      @include media(xmd) {
        float: none;
      }
      .show_product{
        display: block;
        text-align: center;
        background: #fe8f00;
        &:hover{
          background: #fe8f00;
        }
        &.button{
          padding: 2px 10px;
        }
      }
    }
    position: relative;

    & > .btn_add {
      float: right;
      position: relative;
      z-index: 2;
      .button{
        padding: 2px 10px;
      }
    }
  }


  @include media(xlg) {
    .catalog-item__title {
      & > .show_product {
        font-size: 18px;
      }
    }

    .short_description {
      * {
        font-size: 14px;
      }
    }
  }
  @include media(xmd) {
    .catalog-item__title {
      & > .show_product {
        font-size: 12px;
        line-height: 32px;
        margin-bottom: 1em;
      }
    }
  }

  .product-custom-labels{
  .label{
    max-width: 50px;
    margin: 0 0 5px;
    border-radius: 5px;
    padding: 5px;
    img{
    }
    span{
      display: none;
    }
  }
}

}

.desktop {
  .catalog-item {
    .kolvo {
      transition: all 0.3s ease;
      transform: translate3d(0, 0, 0);
    }

    .kolvo_buy_button_add {
      &:before{
        display: none;
      }
      &.hover {
        &:hover {
          &:before{
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 10%;
            height: 100%;
            margin: 0 0 0 -10px;
          }
          .kolvo {
            transform: translate3d(-110%, 0, 0);
            transition: transform 0.3s ease;
            visibility: visible;
            border: 1px solid $linkColor--hover;
          }
        }
      }
    }
  }
}

.flexbox {

  .catalog-item {
    &:before,
    &:after {
      display: none;
    }
  }
}


.catalog-description__top {
  margin: 0 0 2em;
  @include media(xmd) {
    margin: 0;
  }
  h1{
    padding: 0;
    margin: 0;
    @include media(xmd) {
      padding: 0;
    }
  }
}

.catalog-description__bottom {
  margin: 0 0 2em;
}

/* Раскладка карточек в каталоге */
.catalog-list {
  margin: 1em 0 0;
  @extend .clearfix;

  & > .catalog-item {
   float: left;
   margin: 0 1em 2em 0;
   width: calc(20% - 0.8em);

   &:nth-child(5n + 5) {
    margin: 0 0 2em;
    @include media(xmd) {
     margin: 0 0.5em 1em 0;
   }
 }

 @include media(lgs) {
   margin: 0 2em 2em 0;
   width: calc(25% - 1.5em);

   &:nth-child(4n + 4) {
    margin: 0 0 2em;
  }
}

@include media(lg) {
  width: calc(50% - 1em);

  &:nth-child(3n + 3) {
    margin: 0 2em 2em 0;
  }

  &:nth-child(2n + 2) {
    margin: 0 0 2em;
  }
}

@include media(xmd) {
  width: calc(50% - 0.5em);
  border: 1px solid #f2f2f2;
  margin: 0 0.5em 1em 0;
  padding: 0.25em 0 8em 0;
  &:nth-child(3n + 3) {
    margin: 0 0.5em 1em 0;
  }

  &:nth-child(2n + 2) {
    margin: 0 0 1em 0;
  }
}


@include media(sm) {
  // margin: 0 0 2em;
  // width: 100%;
}

}

}


.sort_panel {
  font-style: normal;

  & > span {
    margin: 0 0.5em 0 0;
    position: relative;

    & > a {
      margin: 0 3px 0 0;
    }

    &.this_sort {
      font-weight: bold;
    }

    &.desc_sort {
      &:after {
        content: "\2193";
      }
    }

    &.asc_sort {

      &:after {
        content: "\2191";
      }
    }

    &:first-child {
      margin: 0 0.5em;
    }
  }

  @include media(md) {
    font-size: 14px;
  }

  @include media(sm) {
    font-size: 12px;
  }

}


.catalog-item{
  .dop_atr{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    line-height: 1.9rem;
    padding: 0 0.5em;
    .prod_dop_option{
      font-size: 12px;
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      margin: 0 0.35rem;
      padding: 0;
      &.w-100{
        flex-wrap: wrap;
      }
      svg {
        margin-right: 0.2rem;
      }
      &.param_dostavkatovara{
            width: 100%;
            // margin-right: 0;
      }
    }
  }
}

.cat-title{
  h1{
    padding: 0;
    margin: 0;
  }
}


.owl-carousel{
  .catalog-item{
    .kolvo_buy_button_add {
      margin: 0;
      .composition{
        float: left;
        @include media(xmd) {
          float: left;
        }
      }
    }
  }
}