/* Вид на мобильных девайсах */
.mobile,
.tablet {
  header{
    background: $white;
  }
  .menu-button {
    background: none;
    border: none;
    cursor: pointer;
    float: left;
    outline: none;
    padding: 0;
    position: relative;
    text-align: center;
    width: 45px;
    height: $mobileFixedHeaderHeight;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: $webkitHighlightTapColor;
    user-select: none;
    transform: rotate(0deg);
    transition: 0.5s ease-in-out;

  // @include sideBorder('right');

  & > span {
    display: block;
    position: absolute;
    height: 2px;
    width: 25px;
    background: $white;
    border-radius: 1px;
    opacity: 1;
    left: 8px;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;
    @include media(xmd){
      background: #c5c5c5;
    }

    &:nth-child(1) {
      top: 9px;
      transform-origin: left center;
    }

    &:nth-child(2) {
      top: 19px;
      transform-origin: left center;
    }

    &:nth-child(3) {
      top: 29px;
      transform-origin: left center;
    }

  }

  &.open {
    & > span {
      &:nth-child(1) {
        transform: rotate(45deg);
        top: 8px;
        left: 12px;
      }

      &:nth-child(2) {
        width: 0;
        opacity: 0;
      }

      &:nth-child(3) {
        transform: rotate(-45deg);
        top: 25px;
        left: 12px;
      }
    }
  }
}


.header-top {
 background: $headerTopBgColorMobile;
 backface-visibility: hidden;
 height: $mobileFixedHeaderHeight;
 position: fixed;
 top: 0;
 left: 0;
 overflow: hidden;
 right: 0;
 will-change: transform;
 z-index: 20;
 @include media(xmd){
  background: $white;
  // border-bottom: 2px solid #c5c5c5;
}
.logo{
  display: none;
  height: 44px;
  float: left;
  width: calc(100% - 133px);
  @include media(xmd){
    display: flex;
    align-items: center;
    justify-content: center;
  }
  img{
    width: 100%;
    max-width: 315px;
      // margin: -5px 0 0;
    }
    a{
      @include media(xmd){
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .search {
    float: right;
    padding: 0;
    position: relative;
    height: $mobileFixedHeaderHeight;
    width: $mobileFixedHeaderHeight - 10;

    // @include sideBorder();

    .search-form {
      &.open {
        transform: translate3d(0, 0, 0);
        visibility: visible;
      }
    }
  }


  .search-icon {
    height: $mobileFixedHeaderHeight;
    width: $mobileFixedHeaderHeight - 10;

    @include fontawesome('') {
      // font-size: 24px;
      // color: $white;
      margin: -11px 0 0 -13px;
      background: url(../images/search.png) 0 0 no-repeat;
      background-size: contain;
      width: 25px;
      height: 25px;
      @include media(xmd){
        // color: #c5c5c5;
      }
    }
  }

  .search-form {
    background: $white;
    padding: 10px 34px 10px 35px;
    position: fixed;
    margin: 0;
    top: 0;
    left: 0;
    right: 0;
    height: $mobileFixedHeaderHeight;
    width: auto;
    max-width: none;
    transform: translate3d(0, -100%, 0);
    transition: all 0.3s ease-out;
    z-index: 10;
    visibility: hidden;
    .input-text{
      padding: 3px 1em 3px 0.5em;
    }
  }

  .search-form__button {
    width: $mobileFixedHeaderHeight;

    @include fontawesome('') {
      // font-size: 24px;
      // color: $linkColor--hover;
      // margin: -18px 0 0 -8px;
      margin: -11px 0 0 -8px;
      background: url(../images/search.png) 0 0 no-repeat;
      background-size: contain;
      width: 25px;
      height: 25px;
    }

    &.disabled {
      &:before {
        color: #999;
      }
    }
  }

  .times-block {
    display: inline-block;
    float: none;
    margin: 0.6em 0 0;
    width: auto;

    @include media(slg) {
      display: none;
    }
  }
}


.header-bottom {
  >.container{
    background: none;
    height: auto;
  }
  .logo {
    width: 35%;
    text-align: left;
    @include media(lg) {
      max-width: 40%;
    }
    @include media(xmd) {
      width: 100%;
      max-width: 100%;
      text-align: center;
    }
    @include media(xmd) {
      display: none;
      padding: 0 1em;
    }
    .logo-img{
      max-width: 350px;
      @include media(xmd) {
        max-width: 250px;
      }
    }
  }

  .header-contacts {
    height: auto;
    @include media(lg) {
      padding: 1em 0 0;
      width: 60%;
    }
    @include media(xmd) {
      padding: 0 1em;
      width: 100%;
    }
    @include media(xs) {
      padding: 0 1em 0.5em;
      text-align: center;
    }
  }
}


.header-contacts {
  float: right;
  width: 45%;
  padding: 1em 0 0;
  margin: 0;
  background: none;
  .times {
    padding: 0;
    @include media(xs) {
      position: static;
    }
    &:before {
     margin: -21px 0 0 -25px;
     top: 0;
     @include media(xs) {
      margin: 0;
    }
  }
}

.times-block {
  margin: 10px 0 0;
  padding: 0 1em 0 2em;
  width: 50%;
  line-height: 36px;

  @include media(xs) {
    position: relative;
    margin: 0;
    width: 100%;
    line-height: inherit;
  }
  .title{
    padding: 0;
  }
}

.phones-list {
  // display: block;
  vertical-align: bottom;
  text-align: center;
  @include media(xs) {
    padding: 0;
  }
}
.mail{
  float: left;
  padding: 0;
  margin: 0.4em 0 0;
  text-align: center;
  width: auto;
  @include media(xmd) {
    display: none;
    width: 100%;
  }
  @include media(xs) {
    margin: 0 0 0.3em;
  }
  .mail-wrapper{
    margin: 0;
    @include media(xs) {
      padding: 0;
    }
  }
  .icon{
    &:before{
      @include media(xmd) {
        color: #c5c5c5;
      }
    }
  }
}

.phones {
  float: right;
  padding: 0 1em 0 2em;
  margin: 0;
  text-align: right;
  background: none;
  width: auto;

  @include media(xmd) {
    float: left;
    width: inherit;
    text-align: center;
    padding: 0 3.5em 0 1em;
  }

  .icon{
    display: inline-block;
    position: relative;
    vertical-align: bottom;
    margin: -12px 0 0;
    &:before{
      @include media(xmd) {
        color: #c5c5c5;
      }
    }
  }

  .phones-list__item {
    line-height: 1.7em;
    width: auto;
    float: none;
    > a{
      display: inherit;
    }
  }
}
}


.header-cart {
  float: right;
  height: $mobileFixedHeaderHeight;
  width: $mobileFixedHeaderHeight;
  margin: 0;
  padding: 0;
  -webkit-tap-highlight-color: $webkitHighlightTapColor;

  &.open {
    .cart {
      &.full {
        /*@include spriteIcon(0, -585px) {
          margin: -15px 0 0 -15px;
          height: 28px;
          width: 28px;
          }*/
        }
      }
    }

    .header-cart__title-mob {
      display: none;
    }

    .cart {
      padding: 0;
      width: 100%;
      height: $mobileFixedHeaderHeight;
      &:before{
        width: 32px;
        height: 32px;
        top: 4px;
        left: 4px;
        background: url(../images/cart.png) 0 0 no-repeat;
        background-size: contain;
        // background: url(../images/cart_icon-fixed.png) -6px -2px no-repeat;
        // background-size: 220% auto;
      }
      &.full{
        &:before{
          // top: 0;
          // left: 0;
          // background: url(../images/cart_icon-fixed.png) -64px -17px no-repeat;
          // background-size: 220% auto;
        }
      }
      // @include sideBorder('left');

      /*@include fontawesome('') {
        color: $iconColor;
        font-size: 34px;
        margin: -26px 0 0 -18px;
        left: 50%;
        }*/

      }

      .summ-block {
        display: none;
        padding: 0;
      }

      .header-cart__title {
        display: none;
      }

      .count {
        background: $white;
        position: absolute;
        border-radius: 50%;
        height: 16px;
        padding: 0;
        text-align: center;
        width: 16px;
        top: 50%;
        margin: -11px 0 0 -7px;
        left: 50%;
        z-index: 2;

        .count-quantity {
          color: $black;
          display: block;
          font-size: 11px;
          line-height: 17px;
          letter-spacing: -1px;
          width: auto;
          height: auto;
          border: 0;
          border-radius: 0;
          background: none;
        }

        .count-text {
          display: none;
        }

        .qt {
          display: none;
        }

      }
    }
  }



  @include media(xmd) {
    .header-top {

      .search {
        float: right;
        position: relative;
        width: 4em;
        height: 3em;
        perspective-origin: right center;
      }

    }
  }



