.brands-description__top,
.brands-description__bottom {
  margin: 0 0 2em;
}

.brand-list {
  @extend .clearfix;

  margin: 0 0 2em;
}

.brand-list__item {
  float: left;
  margin: 0 1em 2em 0;
  width: calc(20% - 0.8em);
  
  &:hover {

    .brand-list__item-title {
      & > a {
        color: $linkColor--hover;
      }
    }
  }

  & > a {
    display: block;
  }

  &:nth-child(5n + 5) {
    margin: 0 0 2em;
  }

}

.brand-list__item-image {
  display: block;
  max-width: 200px;
  width: 100%;
}


.brand-list__item-title {
  margin: 0.5em 0 0;
  text-align: center;

  & > a {
    font-size: 13px;
  }

  @include media(lg) {
    & > a {
      font-size: 12px;
    }
  }
}


