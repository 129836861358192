@import url('https:\/\/#{$fontsGoogle}#{':'}#{$weightFonts}&subset=cyrillic');

div, form, table {
  /*outline: 1px solid #3a87ad !important;*/
}
input::-ms-clear {
  display: none;
}
body {
  background: $bgColor;
  font: normal #{$fontSizeBase}/1.5 $fontName, "Helvetica", sans-serif;
  color: $textColor;
  opacity: 0;
  overflow: hidden;
  height: 100vh;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: none;
}
.loaded {
  body {
    overflow-x: hidden;
    overflow-y: scroll;
    height: auto;
    opacity: 1;
  }
  &.desktop{
   body {
    // background: url(../images/footer.png) center bottom no-repeat, url(../images/fon.jpg) center 0 no-repeat fixed;
  }
}
}
table {
  border-collapse: collapse;
}
ul {
  margin-top: 0;
  margin-bottom: 0;
}
ol{
  padding: 0 0 0 1em;
}
.content-inner ul {
  padding-left: 18px;
}
h1 {
  color: $linkColor;
  font-size: 28px;
  @include media(md) {
    font-size: 18px;
  }
}
h2 {
  color: $linkColor;
  font-size: 24px;
  @include media(md) {
    font-size: 16px;
  }
}
h3 {
  color: $linkColor;
  font-size: 18px;
  @include media(md) {
    font-size: 14px;
  }
}
h1 , h2, h3, h4, h5, h6, p {
  padding: 0 0 1em;
}
a {
  color: $linkColor;
  transition: color 0.3s ease;
  text-decoration: underline;
  -webkit-tap-highlight-color: $webkitHighlightTapColor;
}
a:hover {
  color: $linkColor--hover;
  text-decoration: underline;
  transition: color 0.3s ease;
}
.alert {
  color: red;
}
.noscript_alert {
  margin-bottom: 10px;
  padding: 5px;
  border: 1px solid red;
  text-align: center;
}
.noscript_alert > span {
  font-weight: bold;
  color: red;
}
.content-inner {
  margin: 1em 0 3em;
  .title {
    margin-bottom: 5px;
    text-transform: uppercase;
    font-weight: bold;
  }
  .restore_pass-section {
    display: block;
    margin: 1em 0 0;
    max-width: 320px;
    padding: 1em;
    & > .popup-block__cross {
      display: none;
    }
    & > .popup-block__title {
      display: none;
    }
    & > .restore_pass-form {
      max-width: 292px;
      .form_block {
        margin: 0;
        .form_title {
          position: static;
        }
        &.key-title {
          margin: 0 0 0.5em;
        }
      }
      .input-text {
        width: 100%;
      }
    }
  }
}
.col-3 {
  float: left;
  padding: 0 1em;
  width: 25%;
}
.col-4 {
  float: left;
  padding: 0 1em;
  width: 33.333%;
}
.col-6 {
  float: left;
  padding: 0 1em;
  width: 50%;
}
.col-3-r {
  float: right;
  padding: 0 1em;
  width: 25%;
}
.col-4-r {
  float: right;
  padding: 0 1em;
  width: 33.333%;
}
.col-6-r {
  float: right;
  padding: 0 1em;
  width: 50%;
}
@include media(md) {
  .col-3 {
    width: 50%;
  }
  .col-4 {
    width: 50%;
  }
  .col-6 {
    width: 100%;
  }
  .col-3-r {
    width: 50%;
  }
  .col-4-r {
    width: 50%;
  }
  .col-6-r {
    width: 100%;
  }
}
@include media(sm) {
  .col-3,
  .col-4,
  .col-6,
  .col-3-r,
  .col-4-r,
  .col-6-r {
    width: 100%;
  }
}
.path {
  color: $linkColor;
  display: block;
  text-align: left;
  font-weight: bold;
  font-size: 14px;
  line-height: 1em;
  padding: .6em 0;
  margin: 1em 0 0;
  position: relative;
  &:before {
    background: linear-gradient(to right, $linkColor 0%, $linkColor 15%, rgba(255,255,255,0) 70%);
    content: '';
    height: 1px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
  & > a {
    font-weight: normal;
  }
}
/* Вид хлебных крошек на мобильных */
.mobile,
.tablet {
  @include media(md) {
    .path {
      // background: $headerTopBgColorMobile;
      color: $black;
      font-size: 12px;
      line-height: 22px;
      padding: 0;
      margin: 0;
      border-radius: $borderRadius;
      & > a {
        color: $linkColor;
      }
      &:before {
        display: none;
      }
    }
  }
}
.content {
  position: relative;
  img {
    height: auto;
    max-width: 800px;
    /*width: 100%;*/
    @include media(lg) {
      max-width: 600px;
    }
    @include media(md) {
      max-width: 100%;
    }
  }
}
button,
.button {
  @include button(); /* Основные стили настроек кнопки см. /src/scss/vars */
  font-size: 14px;
  outline: none;
  text-transform: uppercase;
  transition: all 0.3s ease;
}
.yashare_text {
  text-align: right;
}
html.ie9 * {
  border-radius: 0 !important;
}
.wrapper {
  height: 100vh;
  overflow-x: hidden;
  pointer-events: none;
  position: relative;
  z-index: 1;
}
.loaded {
  .wrapper {
    height: auto;
    overflow: hidden;
    pointer-events: all;
    visibility: visible;
  }
}
.mobile,
.tablet {
  &.menu-visible {
    .overlay {
      visibility: hidden;
    }
  }
  .wrapper {
    padding: $mobileFixedHeaderHeight 0 0;
    position: relative;
  }
}
.locked {
  .overlay {
    opacity: 1;
    transition: opacity 0.2s cubic-bezier(0, 0, 0, 1);
    visibility: visible;
  }
}
.icon {
  display: block;
  height: 24px;
  width: 24px;
  position: absolute;
  top: 50%;
  left: 0;
  margin: -12px 0 0;
}
.overlay {
  position: fixed;
  background: rgba(0,0,0,0.65);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  visibility: hidden;
  z-index: 100;
}
.mob-icon {
  font: normal 1.5em/1.2 "FontAwesome";
  position: absolute;
  pointer-events: none;
}
.desktop {
  .mob-icon {
    visibility: hidden;
  }
}
.citronLoader {
  position:absolute;
  top: 0;
  left: 0;
  background: white;
  background: rgba(255, 255, 255, .8);
  z-index: 2;
}
.citronLoader span {
  position:absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  margin:auto;
  width:32px;
  height:32px;
  background:url(/assets/images/ajaxloader.gif) 0 0 no-repeat;
}
.citronLoaderBig {
  position:absolute;
  top: 0;
  left: 0;
  background:white;
  background:rgba(255, 255, 255, .8);
  z-index:2;
}
.citronLoaderBig span {
  position:absolute;
  top:100px;
  left:48%;
  width:66px;
  height:66px;
  background:url(/assets/images/citronloader.gif) 0 0 no-repeat;
}
#ajaxedsearch {
  position:absolute;
  z-index:-1;
  width:1px;
  height:1px;
  overflow:hidden;
}
.content-block.filtrovannoe {
  padding: 10px;
}
.prd_pages_bottom,
.prd_pages_top {
  color: $textColor;
  font-size: 13px;
  text-align: right;
  & > a {
    display: inline-block;
    padding: 0 4px;
    &.sel_page {
      color: $textColor;
      text-decoration: none;
    }
  }
}
.example {
  background: $linkColor;
  color: #fff;
  font-weight: 700;
  display: inline-block;
}
.video-responsive {
  position: relative;
  padding-bottom: 42%;
  height: 0;
  overflow: hidden;
  margin: 0 auto;
  max-width: 73%;
  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
.redz {
  color: #df141e;
}
.radio {
  vertical-align: top;
  width: 17px;
  height: 17px;
  margin: 0 6px 0 0;
  & + label {
    cursor: pointer;
  }
  &:not(checked) {
    position: absolute;
    visibility: hidden;
    &  + label {
      position: relative;
      padding: 0 0 0 35px;
      &:before {
        border-radius: 50%;
        background: #ccc;
        content: '';
        position: absolute;
        top: 50%;
        margin-top: -8px;
        left: 0;
        width: 16px;
        height: 16px;
      }
      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 3px;
        width: 10px;
        height: 10px;
        margin-top: -5px;
        border-radius: 50%;
        background: $linkColor;
        opacity: 0;
        transition: background .2s ease;
      }
    }
  }
  &:checked {
    & + label {
      &:after {
        opacity: 1;
      }
    }
  }
}
.checkbox {
  vertical-align: top;
  & + label {
    cursor: pointer;
    user-select: none;
  }
  &:not(checked) {
    position: absolute;
    visibility: hidden;
    & + label {
      position: relative;
      padding: 3px 0 0 24px;
      &:before {
        border: 1px solid $borderColor;
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        margin-top: -6px;
        width: 12px;
        height: 12px;
        border-radius: $borderRadius;
        background: $white;
      }
      &:after {
        content: '\f00c';
        color: $linkColor;
        font: normal 16px/1 "FontAwesome";
        position: absolute;
        margin-top: -8px;
        top: 50%;
        left: 1px;
        opacity: 0;
        visibility: hidden;
      }
    }
  }
  &:checked {
    & + label:before {
    }
    & + label:after {
      opacity: 1;
      transition: opacity 0.2s ease;
      visibility: visible;
    }
  }
}

.video-bg{
 position:fixed;
 z-index:-1;
 min-width:100%;
 min-height:100%;
 overflow:hidden;
 top: 0;
}

.mobile,
.tablet{
  .button-up {
    right: 10px;
  }
}

.button-up{
  position: fixed;
  bottom: 5em;
  right: 1em;
  text-align: center;
  cursor: pointer;
  z-index: 10001;
  opacity: 0;
  transition: opacity 0.3s ease;
  .fa{
    display: block;
  }
  &.shown{
    opacity: 1;
    transition: opacity 0.3s ease;
    &:hover{
      color: $linkColor;
      transition: color 0.3s ease;
    }
  }
}

.mobile,
.tablet{
  .button-up{
    &.shown{
      color: #c7c7c7;
    }
  }
  &.video_on{
    .button-up{
      &.shown{
        color: #c7c7c7;
      }
    }
  }
}

.video_on{
  .button-up{
    &.shown{
      color: $white;
    }
  }
}

.left-m{
  width: 40px;
  display: none;
  height: 100px;
  position: fixed;
  cursor: pointer;
  bottom: 100px;
  z-index: 99999;
  margin-top: 0px;
  left: 0;
  border-left: none;
  transition: all 0.3s ease-in-out;
  opacity: 0.9;
  background: $linkColor;
  border-radius: 0 $borderRadius $borderRadius 0;
  // background: url(../images/fon_menu_top.jpg) center 0;
  @include media(xmd) {
    display: block;
  }
  a{
    position: relative;
    display: block;
    height: 100%;
    &::before {
      content: "Каталог";
      transform: rotate(-90deg);
      position: absolute;
      left: -20px;
      top: 40px;
      color: white;
      font-weight: 600;
      font-size: 18px;
      text-shadow: 1px 2px 1px #3c3c3c;
    }
    &::after {
      content: '';
      position: absolute;
      right: -6px;
      top: 40px;
      border: 10px solid rgba(255, 255, 255, 0);
      border-left: 10px solid white;
      transition: all 0.3s ease-in-out;
      text-shadow: 1px 2px 1px #3c3c3c;
    }
  }
}

.img-fluid {
  max-width: 100% !important;
  height: auto !important;
}

    .desktop-only {display: block;}
    .mobile-only {display: none;}

    ._black_button:hover {
      background: #e54b13;
      color: #fff;
      border-color: #fff;
    }
  
    .product_photo {
      position: relative;
    }
    ._dopIncs {
      background: #fee696;
      outline: 1px solid #efefef;
      font-size: 11px;
      padding: 0.25rem 0.25rem 0 0.25rem;
      position: absolute;
      left: 1rem;
      bottom: 1rem;
      z-index: 2;
    }

    .widget-copyright{
      display: none;
    }
    .module.video p {
      padding: 0 0 0.1em;
    }
    .module.video iframe {
      border-radius: 5px;
      outline: 0;
    }
    @media only screen and (max-width: 768px){
      .module.video .module-wrapper iframe {
        width: 100%;
        height: 200px;
        padding: 1em;
      }
      
      .order_button
      , .min_summa_zakaza{
        text-align: center;
        width: 100%;
        padding-top: 1rem;
        padding-bottom: 1rem;
      }
      
      .desktop-only {display: none;}
      .mobile-only {display: block;}
      
    }

/*filter corrects*/
.filters-main .ui-state-default, 
.filters-main .ui-widget-content .ui-state-default, 
.filters-main .ui-widget-header .ui-state-default, 
.filters-main .ui-button, 
html .ui-button.ui-state-disabled:hover, 
html .ui-button.ui-state-disabled:active {
  border: 1px solid #ffcb45!important;
  background: #ffcb45!important;
  border-radius: 100px;
  padding: 0.65rem;
}
.filters-main .ui-slider-horizontal .ui-slider-handle {
  top: -1.2em!important;
  margin-left: -.6em;
}
.filters-main .ui-widget {
  font-size: .55em!important;
}

.filters-main .ui-widget.ui-widget-content {
  border: 1px solid #ffcb45!important;
}

.filters-main .ui-widget-header {
  background: #ffcb45!important;
}
.filter_block_wrapper .filter_block {
  // width: 25%!important;
}

._pseudo_link {
  color: red;
  padding: .5rem 0;
  text-align: center;
  cursor: pointer;
}

._pseudo_link span {
  font-size: 1.5rem;
  bottom: -.15rem;
  position: relative;
}

.filter_block.poiskat {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1em;
}

.poiskat .button {
  padding: 0.5em 1em;
  background: #e54b13;
  text-wrap: nowrap;
}

   .range_slider {
    margin-bottom: 1rem;
   }

   .filters-main .checkbox:not(checked)+label:before {
    margin-top: -8px!important;
    width: 20px!important;
    height: 20px!important;
    border-radius: 1px!important;
   }

   .filter_block_wrapper .filter_mid .input-wrapper.check {
    margin: 0 0 1.5em 0!important;
    width: calc(50% - 5px)!important;
    float: left;
   }

   .filter_block_wrapper .checkbox:not(checked)+label {
    padding: 3px 0 0 30px;
    white-space: nowrap;
   }

   .filter_block_wrapper .filter_top>span {
    font-size: 14px!important;
    font-weight: bold;
   }
   /*filter corrects*/