/* Базовые: Ширина контента<br><span>($containerWidth)</span> */
$containerWidth: 1400px;
/* Базовые: Подключение Google Fonts<br><span>($fontsGoogle)</span> */
$fontsGoogle: "fonts.googleapis.com/css?family=Roboto";
/* Базовые: Толщина шрифта<br><span>($weightFonts)</span> */
$weightFonts: 400,500,700,900;
/* Базовые: Название шрифта<br><span>($fontName)</span> */
$fontName: "Roboto";
/* Базовые: Размер шрифта<br><span>($fontSizeBase)</span> */
$fontSizeBase: 14px;
/* Базовые: Белый цвет<br><span>($white)</span> */
$white: #fff;
/* Базовые: Черный цвет<br><span>($black)</span> */
$black: #000;
/* Базовые: Цвет ссылок<br><span>($linkColor)</span> */
$linkColor: #B34721;
/* Базовые: Цвет ссылок при наведении<br><span>($linkColor--hover)</span> */
$linkColor--hover: #7D5301;
/* Базовые: Подсветка ссылки<br><span>($webkitHighlightTapColor)</span> */
$webkitHighlightTapColor: rgba(0,0,0, 0.15);
/* Базовые: Цвет текста<br><span>($textColor)</span> */
$textColor: #000;
/* Базовые: Серый цвет<br><span>($grey)</span> */
$grey: #666;
/* Базовые: Цвет иконок<br><span>($iconColor)</span> */
$iconColor: $linkColor;
/* Базовые: Цвет фона<br><span>($bgColor)</span> */
$bgColor: #fff;
/* Базовые: Цвет фона после загрузки страницы<br><span>($bgLoaded)</span> */
$bgLoaded: url(../images/bg.jpg) 0 0;
/* Базовые: Цвет фона верхней шапки<br><span>($headerTopBgColor)</span> */
$headerTopBgColor: url(../images/header2.png) 0 12px no-repeat;
/* Базовые: Цвет фона нижней шапки<br><span>($headerBottomBgColor)</span> */
$headerBottomBgColor: #fff;
/* Базовые: Цвета ошибок<br><span>($colorError)</span> */
$colorError: rgba(253, 30, 15, 0.7);
/* Базовые: Цвета ОК<br><span>($colorOk)</span> */
$colorOk: rgba(169, 253, 0, 0.7);
/* Базовые: Цвет фона верхней шапки на мобильных<br><span>($headerTopBgColorMobile)</span> */
$headerTopBgColorMobile: $linkColor;
/* Базовые: Высота мобильной фиксированной шапки<br><span>($mobileFixedHeaderHeight)</span> */
$mobileFixedHeaderHeight: 44px;
/* Header: Logo раположение<br><span>($logoPos)</span> */
$logoPos: left;
/* Header: Logo margin<br><span>($logoMargin)</span> */
$logoMargin: 0;
/* Header: Logo padding<br><span>($logoPadding)</span> */
$logoPadding: 0;
/* Header: Logo ширина<br><span>($logoWidth)</span> */
$logoWidth: 66%;
/* Header: Logo img ширина<br><span>($logoWidth)</span> */
$logoImgwidth: none;
/* Header: Logo img макс ширина<br><span>($logoImgmaxWidth)</span> */
$logoImgmaxWidth: 565px;
/* Header: Logo img высота<br><span>($logoImgheight)</span> */
$logoImgheight: none;
/* Header: Logo img макс высота<br><span>($logoWidth)</span> */
$logoImgmaxHeight: none;
/* Header: Header-Contacts раположение<br><span>($heaContFloat)</span> */
$heaContFloat: left;
/* Header: Header-Contacts padding<br><span>($heaContPadding)</span> */
$heaContPadding: 0;
/* Header: Header-Contacts ширина<br><span>($heaContWidth)</span> */
$heaContWidth: 20%;
/* Footer: Footer-Top background<br><span>($footerTopBgColor)</span> */
$footerTopBgColor: none;
/* Footer: Footer-Bottom background<br><span>($footerBottomBgColor)</span> */
$footerBottomBgColor: none;
/* Рамки: Цвет<br><span>($borderColor)</span> */
$borderColor: #aaa;
/* Рамки: Скругление<br><span>($borderRadius)</span> */
$borderRadius: 5px;
/* Рамки: Стиль<br><span>($border)</span> */
$border: 1px solid $borderColor;
/* Кнопки: Градиент да/нет? Если нет, используется цвет 1<br><span>($isButtonGradient)</span> */
$isButtonGradient: false;
/* Кнопки: Тень, да/нет<br><span>($isButtonShadow)</span> */
$isButtonShadow: true;
/* Кнопки: Рамка, да/нет<br><span>($isButtonBorder)</span> */
$isButtonBorder: true;
/* Кнопки: Цвет фона 1 (нижний)<br><span>($buttonBgColor1)</span> */
$buttonBgColor1: #FF7F50;
/* Кнопки: Цвет фона 1 (верхний)<br><span>($buttonBgColor2)</span> */
$buttonBgColor2: $linkColor--hover;
/* Кнопки: Тень<br><span>($buttonShadow)</span> */
$buttonShadow: inset 0px 1px 1px rgba(255,255,255,0.3), 1px 1px 3px -2px #000000;
/* Кнопки: Цвет текста<br><span>($buttonColor)</span> */
$buttonColor: #fff;
/* Кнопки: Скругления<br><span>($buttonBorderRadius)</span> */
$buttonBorderRadius: $borderRadius;
/* Кнопки: Цвет фона 2 (нижний) при наведении<br><span>($buttonBgColor1--hover)</span> */
$buttonBgColor1--hover: #ff9061;
/* Кнопки: Цвет фона 2 (верхний) при наведении<br><span>($buttonBgColor2--hover)</span> */
$buttonBgColor2--hover: #ff9061;
/* Кнопки: Цвет текста при наведении<br><span>($buttonColor--hover)</span> */
$buttonColor--hover: #fff;
/* Кнопки: Рамка<br><span>($buttonBorder)</span> */
$buttonBorder: 3px solid #fff;
/* Кнопки: Цвет рамки при наведении<br><span>($buttonBorderColor--hover)</span> */
$buttonBorderColor--hover: #fff;
/* Кнопки: Тень наведении<br><span>($buttonShadow--hover)</span> */
$buttonShadow--hover: none;
/* Кнопки: Цвет фона 1 (нижний) активный<br><span>($buttonBgColor1--active)</span> */
$buttonBgColor1--active: #0a3454;
/* Кнопки: Цвет фона 2 (верхний) активный<br><span>($buttonBgColor2--active)</span> */
$buttonBgColor2--active: #143f61;
/* Кнопки: Цвет текста активный<br><span>($buttonColor--active)</span> */
$buttonColor--active: #fff;
/* Кнопки: Цвет рамки активный<br><span>($buttonBorderColor--active)</span> */
$buttonBorderColor--active: #1b9fae;
/* Кнопки: Тень в активном состоянии<br><span>($buttonShadow--active)</span> */
$buttonShadow--active: none;
/* Инпуты: Есть ли рамка<br><span>($isInputBorder)</span> */
$isInputBorder: true;
/* Инпуты: Есть ли тень<br><span>($isInputShadow)</span> */
$isInputShadow: true;
/* Инпуты: Цвет текста<br><span>($inputColor)</span> */
$inputColor: $textColor;
/* Инпуты: Цвет фона<br><span>($inputBg)</span> */
$inputBg: #fff;
/* Инпуты: Цвет рамки<br><span>($inputBorderColor)</span> */
$inputBorderColor: $borderColor;
/* Инпуты: Тень<br><span>($inputShadow)</span> */
$inputShadow: inset 0 2px 7px 0 #a6a6a7;
/* Модули: Цвет фона<br><span>($moduleBg)</span> */
$moduleBg: #efefef;
/* Модули: Цвет фона заголовков<br><span>($moduleHeaderBg)</span> */
$moduleHeaderBg: #f2f2f2;
/* Модули: Цвет заголовков<br><span>($moduleHeaderColor)</span> */
$moduleHeaderColor: #000;
/* Модули: Рамка<br><span>($moduleBorder)</span> */
$moduleBorder: 0;
/* Модули: Тень сверху<br><span>($module_top__box-shadow)</span> */
$module_top__box-shadow: 5px 5px 5px 0 rgba(0, 0, 0, 0.1);
/* Модули: Цвет фона заголовков в мобильной версиии<br><span>($moduleMobileHeaderBg)</span> */
$moduleMobileHeaderBg: #f2f2f2;
/* Модули: Цвет заголовков в мобильной версиии<br><span>($moduleMobileHeaderColor)</span> */
$moduleMobileHeaderColor: $white;
/* Горизонтальное меню: Градиент да/нет Если нет, используется цвет 1<br><span>($isTopMenuGradient)</span> */
$isTopMenuGradient: false;
/* Горизонтальное меню: Тень да/нет<br><span>($isTopMenuShadow)</span> */
$isTopMenuShadow: false;
/* Горизонтальное меню: Рамка да/нет<br><span>($isTopMenuBorder)</span> */
$isTopMenuBorder: false;
/* Горизонтальное меню: Закругления да/нет<br><span>($isTopMenuBorderRounded)</span> */
$isTopMenuBorderRounded: false;
/* Горизонтальное меню: Цвет фона 1 (нижний)<br><span>($topMenuBgColor1)</span> */
$topMenuBgColor1: none;
/* Горизонтальное меню: Цвет фона 2 (верхний)<br><span>($topMenuBgColor2)</span> */
$topMenuBgColor2: #045ea1;
/* Горизонтальное меню: Рамка<br><span>($topMenuBorder)</span> */
$topMenuBorder: 1px solid #aaa;
/* Горизонтальное меню: Тень кнопок<br><span>($topMenuShadow)</span> */
$topMenuShadow: 1px 2px 3px 0 #a8a8a8;
/* Горизонтальное меню: Цвет текста<br><span>($topMenuColor)</span> */
$topMenuColor: #00ff48;
/* Горизонтальное меню: Скругления<br><span>($topMenuBorderRadius)</span> */
$topMenuBorderRadius: 0px;
/* Горизонтальное меню: Цвет текста при наведении<br><span>($topMenuColor--hover)</span> */
$topMenuColor--hover: #edac0a;
/* Горизонтальное меню: Цвет фона 1 (нижний)<br><span>($topMenuBgColor1--selected)</span> */
$topMenuBgColor1--selected: #dd8200;
/* Горизонтальное меню: Цвет фона 2 (верхний) выбранный раздел<br><span>($topMenuBgColor2--selected)</span> */
$topMenuBgColor2--selected: #eeb00b;
/* Товар: Цвет заголовка<br><span>($prodTitleColor)</span> */
$prodTitleColor: $linkColor;
/* Товар: Цвет текста<br><span>($prodTextColor)</span> */
$prodTextColor: $textColor;
/* Товар: Цвет текста следующий<br><span>($prodSecondColor)</span> */
$prodSecondColor: #8f8f8f;
/* Товар: Цвет текущей цены<br><span>($prodCurrentPriceColor)</span> */
$prodCurrentPriceColor: $black;
/* Товар: Цвет старой цены<br><span>($prodOldPriceColor)</span> */
$prodOldPriceColor: #a4a4a4;