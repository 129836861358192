
.popup-block__fon {
  background: rgba(0,0,0,.5);
  display: none;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1000;
  position: fixed;
}

.popup-block {
  background: #fff;
  box-shadow: 7px 10px 60px 0 #383838;
  border-radius: $borderRadius;
  display: none;
  font-size: 15px;
  width: 410px;
  margin: -100px 0 0 -205px;
  text-align: center;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 1010;

  .popup-block__content {
    margin: 1em 0 0;
    text-align: center;
  }


  .input-text {
    width: 100%;
  }

  .input-textarea {
    height: 8em;
  }

  .popup-block__inner {
    padding: 0.5em 1em 1em;
    position: relative;
  }

  .form_block {
    &.check-agree {

    }

    &.form_block__last {
      margin: 0;

      .fb_title {
        font-size: 11px;
      }
    }

  }

  .popup-block__title {
    font-weight: 700;
    text-transform: uppercase;
  }


  @include media(sm) {

    .popup-block__content {
      margin: 0.5em 0 0;
    }

    .popup-block__title {
      font-size: 14px;
    }

    .form_block {
      &.check-agree {
        & > label {
          font-size: 9px;
          line-height: 1.4;
        }
      }
    }
  }
}


.popup-block__cross {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  height: 36px;
  width: 40px;
  will-change: transform;
  z-index: 2;
  -webkit-tap-highlight-color: $webkitHighlightTapColor;

  &:before {
    content: '\f2d3';
    display: block;
    font: normal 29px/1 "FontAwesome";
    color: $linkColor--hover;
    position: relative;
    top: 3px;
  }

  &:hover {
    &:before {
      color: $linkColor;
      transition: color 0.3s ease;
    }
  }

}


.popup-block {

  &.reg-window {
    margin: -96px 0 0 -224px;
    width: 448px;

    .reg_auth-section {

      .addition-block {
        float: right;
        margin: 8px 0 0;
        text-align: right;

        & > a {
          display: block;
          font-size: 13px;
          line-height: 1.4;
          margin: 0 0.5em;
          -webkit-tap-highlight-color: transparent;

          & > span {
            -webkit-tap-highlight-color: $webkitHighlightTapColor;
          }
        }
      }

      .form_block {

        &.form_block__last {
          float: left;
          padding: 0.5em 0 0;
          width: 100px;

          & > .form_title {
            font-size: 11px;
            top: -16px;
          }
        }
      }
    }


    @include media(sm) {
      width: calc(320px - 2em);
      margin: -90px 0 0 calc(160px - 320px + 1em);


      .popup-block__title {
        padding: 0 1.5em;
        margin: 0 0 0.5em;
      }

      .addition-block {
        float: right;
        margin: -5px 0 0;
        text-align: right;
        width: 120px;

        & > a {
          font-size: 13px;
          display: inline-block;
        }
      }
    }
  }
}

.restore_pass-section {
  background: $white;
  display: none;
  height: auto;
  padding: 0.5em 1em 1em;

  .form_block {

    &.key-block {
      .input-text {
        float: left;
        width: calc(100% - 1em - 92px);
      }

      .key-img {
        position: absolute;
        top: -10px;
        right: 0;
        height: 34px;
        width: 92px;
      }

      .help-block {
        right: 11em;
      }
    }

    &.key-title {
      margin: 0.5em 0 0;

      .form_title {
        font-size: 11px;
        position: static;
      }
    }


    &.form_block__last {
      .button {
        margin: 1em 0 0;
      }
    }
  }
}


.popup-block {
  &.auth-ok {
    margin-top: -3em;
  }
}


.popup-block {
  &.auth-error {
    margin-top: -3em;
  }
}

.popup-block {
  &.order-call {
    margin-top: -172px;

    @include media(sm) {
      max-width: calc(320px - 2em);
      margin: -12em 0 0 calc(160px - 320px + 1em);
      width: 100%;
    }
  }
}


.popup-block {
  &.sendformok {
    margin-top: -3em;
  }
}


.popup-block {
  &.callbackformok {
    margin-top: -3em;
  }
}


.popup-block {
  &.add-to-cart {
    margin: -46px 0 0 -202px;

    .link-to-cart {
      @extend button;
      position: relative;
    }

    @include media(sm) {
      margin: -42px 0 0 -145px;
      max-width: 290px;
      width: 290px;
    }

  }
}



.popup-block {
  &.compare-popup {
    top: 50%;
    left: 50%;
    height: 800px;
    margin: -400px 0 0 calc(-600px + 1em);
    width: calc(1200px - 2em);

    @include media(xlg) {
      margin: -400px 0 0;
      left: 1em;
      right: 1em;
      width: auto;
    }
  }


  &.compare-popup-error {
    width: 404px;
    margin: -39px 0 0 -202px;
  }
}




// Окно быстрого просмотра продукта
.product-popup {
  height: 100vh;
  left: 0;
  outline: medium none;
  overflow-x: hidden;
  overflow-y: scroll;
  position: fixed;
  top: 0;
  vertical-align: middle;
  width: 100vw;
  z-index: 1100;
  -webkit-overflow-scrolling: touch;
}


.product-popup__window {
  position: relative;
  left: 0;
  right: 0;
  top: 1em;
  bottom: 1em;
  margin: auto;
  background: #fff;
  border: 1px solid #ccc;
  max-width: $containerWidth;
  padding: 1em;
  width: 100%;
  z-index: 901;

  @include media(md) {
    top: 0;
    bottom: 0;
  }
}


.product-popup__close {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 2px;
  padding: 6px 6px;
  -webkit-tap-highlight-color: $webkitHighlightTapColor;

  &:before {
    content: '\f2d3';
    display: block;
    font: normal 29px/1 "FontAwesome";
    color: $linkColor--hover;
    position: relative;
    top: 0;
  }

  &:hover {
    &:before {
      color: $linkColor;
      transition: color 0.3s ease;
    }
  }
}



#layerok {
  width: 100%;
  height: 100%;
  position:fixed;
  top: 0;
  left: 0;
  z-index: 900;
  background: rgba(0, 0, 0, .3);
}

.g-recaptcha{
  @include media(sm){
    transform: scale(.8);
    margin: 0 0 0 -20px;
  }
  > div{
    margin: 0 auto;
  }
}
.g-recaptcha-bubble-arrow{
  + div{
    width: 100% !important;
    left: -25%;
  }
}