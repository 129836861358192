.input-text,
.input-textarea {
  @include input();
  appearance: none;
}

.content-block{
  .reg_auth-section .form_block:not(.form_block__last) .form_title{
    position: initial;
  }
  .reg_auth-section{
    max-width: 260px;
    background: #fff;
    margin:1em 0 0;
    padding: 0 1em 1em;
  }
  .form_block.form_block__last{
    margin: 0;
  }
  .addition-block a{
    margin-right: 5px;
  }
}

.input-textarea {
  padding: 0.5em 1em;
}


textarea {
  display: block;
  resize: none;
  padding: 0.5em 1em;
}



.form_block {
  margin: 1.25em 0 0;
  position: relative;
  text-align: left;

  .form_title {
    color: $grey;
    font-size: 12px;
    pointer-events: none;
    line-height: 1.5;
  }

  &.form_block__last {
    padding: 0;
    margin: -1em 0 0;

    .button {
      margin: 0.5em 0 0;
    }
  }

  &:not(.form_block__last) {

    .form_title {
      position: absolute;
      top: 4px;
      left: 0.75em;
      z-index: 10;
    }
  }
}



.has-error {

  & > label {
    color: $colorError;
  }

  & > .input-text,
  & > .input-textarea {
    border-color: $colorError;
  }
}


.has-success {

  & > .input-text,
  & > .input-textarea {
    border-color: $colorOk;
  }
}


.form_content {
  position: relative;
}


.help-block {
  font-size: 11px;
  position: absolute;
  top: 6px;
  right: 1em;
  pointer-events: none;

  &.form-error {
    color: $colorError;
  }
}


.check-agree {
  padding: 0.5em 0;
  margin: 0;

  .checkbox {
    &:not(checked) {
      & + label {
        &:before {
          top: 3px;
          left: 0;
          margin-top: 0;
        }

        &:after {
          top: 1px;
          left: 1px;
          margin-top: 0;
        }
      }
    }
  }



  label {
    cursor: pointer;
    font-size: 11px;
    display: inline-block;
    line-height: 1.333;

    &.has-error {
      color: $colorError;
    }
  }

  .input-form {
    margin: 0 0.5em 0 0;
    vertical-align: bottom;
    width: auto;
  }


}



.payFlex {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
}


.payType {
  margin: 6px 0;
  border-radius: $borderRadius;
  box-shadow: 0 0 4px -1px $borderColor;
  background: #fff;
  cursor: pointer;
  padding: 10px 2px;
  font-size: 12px;
  font-weight: bold;
  width: 100%;
  transition: ease all .02s;
  -webkit-transition: ease all .02s;
  position: relative;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;


  &.active {
    box-shadow: 0 0 4px 2px $linkColor;
  }


  & > div {
    line-height: 35px;
  }


  & > img {
    height: 35px;
    max-width: 70px;
    margin: 0 1em 0 0;
  }


  .radio {
    &:not(checked) {
      & + label {
        padding: 0;
        height: 32px;
        width: 32px;

        &:after {
          left: 50%;
          margin-left: -5px;
        }

        &:before {
          margin-left: -8px;
          left: 50%;
        }
      }
    }
  }
}



.ui-datepicker .ui-widget-header {
  background: $linkColor;
  color: #fff;
}

.ui-datepicker td span,
.ui-datepicker td a {
  text-align: center !important;
}

.ui-datepicker td > a {
  background: none !important;
  border: 1px solid transparent !important;
}

.ui-datepicker td.ui-datepicker-week-end > a {
  color: $linkColor !important;
  background: none !important;
  border: 1px solid transparent !important;
}

.ui-datepicker td > a:hover {
  color: inherit !important;
  background: #e6e6e6 !important;
  border: 1px solid #d3d3d3 !important;
}


.ui-datepicker td.ui-datepicker-today > a {
  color: $white !important;
  background: $linkColor !important;
  border: 1px solid $linkColor !important;
}


.ui-datepicker td.ui-datepicker-current-day > a {
  color: inherit !important;
  border: 1px solid $linkColor !important;
}


.ui-datepicker th.ui-datepicker-week-end > span {
  color: $linkColor !important;
  background: none !important;
  border: 1px solid transparent !important;
}


.ui-datepicker td.ui-datepicker-week-end > a:hover {
  color: $linkColor !important;
}
