.container {
  margin: 0 auto;
  max-width: $containerWidth;
  width: 100%;

  @extend .clearfix;
}

main {
  width: 100%;
  > .container{
    position: relative;
    background: $white;
    box-shadow: 0px 5px 0px rgba(0, 0, 0, 0.2);
    // border-radius: $borderRadius;
    @include media(lg) {
      border-radius: 0;
    }
    &:before{
      display: none;
      content: '';
      position: absolute;
      bottom: -70px;
      right: 0;
      width: 219px;
      height: 77px;
      background: url(../images/3.png) no-repeat;
      z-index: -1;
      opacity: .3;
    }
  }
}

aside {
  float: left;
  padding: 0 1em;
  width: 230px;

  @include media(lg) {
    float: none;
    width: 100%;
  }
}

.content-block {
  float: right;
  padding: 0 1em;
  width: 100%;
  // width: calc(100% - 230px);
  min-height:calc(100vh - 160px - 79px - 147px);

  @include media(lg) {
    float: none;
    width: 100%;
    padding: 1em 1em 0;
  }
  @include media(lg) {
    padding: 0 0.5em 0;
  }
}
html{
  &.inner{
    .content-block{
      width: 100%;
    }
  }
}
.top-block{
  @extend .clearfix;
  .lm-b{
    float: left;
    padding: 0 1em;
    width: 230px;
  }
  .sb-b{
    float: right;
    padding: 0 1em;
    width: calc(100% - 230px);
  }
  .yandex_review{
    margin: 1em 0 0;
    .ya_review_block{
      padding: 0;
      .scores {
        font-size: 25px;
      }
      .ratings_block{
        img {
          width: 15px;
          height: 15px;
        }
        .ratings_text{
          font-size: 15px;
          display: inline-block;
          vertical-align: text-bottom;
          padding: 0 0 0 5px;
        }
      }
    }
    img {
      width: 100px;
    }
  }
  .photo_review{
    margin: 0;
    p{
      padding: 0;
    }
    img{
      width: 100%;
      height: auto;
      max-width: 100px;
    }
  }
}
.mobile,
.tablet {
  .top-block{
    display: none;
  }
}