.gray-outline{
	padding: 1em;
	@include module(false);
	margin: 0;
	box-shadow: 0px 2px 3px #808080;
	border-radius: $borderRadius;
}
.gray-outline-mobile{
	position: relative;
	padding: 1em;
	background: $moduleHeaderBg;
	&:before,
	&:after{
		content: '';
		position: absolute;
		top: 0;
		width: 1em;
		height: 100%;
		background: $moduleHeaderBg;
		z-index: 0;
	}
	&:before{
		left: -1em;
	}
	&:after{
		right: -1em;
	}
}
.module {
	@include module(false);
	box-shadow: 0px 2px 3px #808080;
	border-radius: $borderRadius;
	&.editable_field{
		background: none;
		box-shadow: none;
		border-radius: 0;
		.module-header {

		}
	}
	.module-header {
		border-radius: $borderRadius;
		position: relative;
		// background: url(../images/fon_module.jpg) 0 0 no-repeat;
		// background-size: cover;
		background: $moduleBg;
		&:after{
			display: none;
			content: '';
			position: absolute;
			height: 1px;
			width: 100%;
			background: #f1dc4c;
			border-top: 1px solid #524803;
			border-bottom: 1px solid #524803;
			left: 0;
			right: 0;
			bottom: -2px;
			margin: 0 auto;
		}
	}
	.filter-wrapper,
	.vote-block{
		padding: 1em;
	}
	.news-block{
		padding: 1em 1em 0;
	}
	.module-wrapper{
		
	}
	&.catalog{
		.module-header{
			>span{
				font-size: 22px;
				a{
					color: #fff;
					text-decoration: none;
				}
			}
		}
	}
	&.gbook{
		display: none;
		.module-header{
			> span{
				display: block;
				> a{
					display: block;
				}
			}
		}
		.module-wrapper{
			display: none;
		}
	}
	&.video{
		.module-header{
			background-size: 100% 100%;
			span{
				font-size: 18px;
				line-height: 22px;
			}
		}
		.module-wrapper{
			text-align: center;
			padding: 1em 0 0;
			@include media(md){
				display: block !important;
			}
			video{
				border-radius: 5px;
				outline: none;
				@include media(md){
					width: 100%;
					height: 200px;
					padding: 1em;
				}
			}
		}
	}
}
.main-left-menu{
	@include media(lg){
		padding: 1em;
	}
}
/* Оформление модулей в контент области */
.module-inner {
	clear: both;
	margin: 1em 0 2em;
	& > .module-header {
		background: none;
		padding: .3em .5em;
		margin: 0 0 2em;
		text-align: center;
		@include media(lg){

		}

		& > span,
		& > h1 {
			font-weight: 500;
			font-size: 30px;
			text-align: center;
			margin: 0 0 25px;
			
			// font-size: 18px;
			// font-weight: 700;
			color: $textColor;
			// text-transform: uppercase;
			padding: 0;
			// margin: 0;
			@include media(lg){
				font-size: 18px;
			}
			a{
				text-decoration: none;
				color: $textColor;
			}
		}
	}
	.module-header-bottom{
		padding: 1em;
	}
	.module-header-info{
	    display: inline-block;
		background: #efefef;
		padding: 0.5em 1em;
		margin: 0 0 0.5em;
		border-radius: $borderRadius;
		@include media(xs){
			margin-top: 1em;
		}
		ul{
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			padding: 0;
			li{
				display: flex;
    			align-items: center;
				padding: 0.2em 0.5em;
				list-style: none;
				border-radius: $borderRadius;
				&:hover{
					background: $white;
				}
				&:not(:first-child){
					margin: 0 0 0 1em;
				}
				img{
					margin: 0 0.5em 0 0;
				}
				&.yandex-maps{
				}
				a{
					color: $textColor;
					text-decoration: none;
				}
			}
		}
	}
	&.catalog{
		margin: 1em 0 0;
		padding: 0 1em;
		+ .content{
			.content-inner{
				margin: 0;
			}
		}
	}
	&.gbook{
		.module_mid{
			display: flex;
			flex-wrap: wrap;
			.news{
				flex: 0 0 33.33333%;
				max-width: 33.33333%;
				padding: 0 2em;
				margin: 0 0 2em;
				@include media(lg){
					flex: 0 0 50%;
					max-width: 50%;
				}
				@include media(md){
					flex: 0 0 100%;
					max-width: 100%;
				}
				.news_datetitle{
					display: flex;
					flex-wrap: wrap;
					justify-content: space-between;
					padding: 10px 0;
					.news_title{
						font-weight: bold;
					}
				}
				.news_text{
					line-height: 22px;
					overflow: hidden;
					&.hide{
						position: relative;
						height: 100px;
						&:after{
							content: "";
							display: block;
							height: 100px;
							position: absolute;
							left: 0;
							right: 0;
							bottom: 0;
							background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #fff 75%)
						}
						+ .content_toggle{
							margin: -20px 0 0;
							display: block;
							position: relative;
						}
					}
				}
			}
			.news_all{
				flex: 0 0 100%;
				max-width: 100%;
				text-align: center;
				text-transform: uppercase;
			}
		}
		&._slider{
			.owl-item{
				padding: 0.5em;
			}
			.module_mid{
				display: block;
				box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
				border-radius: 10px;
				transition: box-shadow 0.3s ease;
				padding: 0 1em 1em;
				&:hover {
					box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
				}
				.news{
					flex: unset;
					max-width: unset;
					padding: 0;
					margin: 0;
					.news_datetitle{
						flex-direction: column;
					}
					.news_text{
						&.hide{
						}
					}
				}
			}
			.module_bot{
				&.news_all{
					text-align: center;
					margin: 1em 0 0;
				}
			}
		}
	}
	&.helping-children{
		.item{
			.item-image{
				margin: 0 auto;
				display: block;
			}
		}
	}
	&.bestsellers{
		.owl-stage{
			@include media(md) {
				display: flex;
			}
		}
		.owl-item{
			order: 1;
			&:nth-child(1) {
				@include media(md) {
					order: 2;
				}
			}
		}
		> .module-header{
			// text-transform: uppercase;
			> span{
				// font-size: 24px;
				// font-weight: bold;
				// color: #993300;
			}
		}
	}
	&.factories{
		.item{
			margin: 0 0 1em;
		}
		> .module-header{
			> span{
				// font-size: 24px;
				// font-weight: bold;
				// color: #993300;
			}
		}
		.item{
			overflow: hidden;
			position: relative;
			border: 1px solid #dfdfe0;
			transition: all .15s linear;
			img{
				margin: 0 auto;
				display: block;
			}
		}
	}
}
.module-wrapper {
	position: relative;
}
/* Бренды */
.module.brands {

	.brands-list {
		display: block;
		list-style: none;
		padding: 1em;
	}

	.brands-list__item {
		font-size: 1.14286em;
		line-height: 1.9;

		& > span {
			color: $linkColor--hover;
		}
	}

	.brands-list__link {
		color: $linkColor;
		transition: color 0.3s ease;

		&:hover {
			color: $linkColor--hover;
		}
	}
}
.module.brands {
	@include media(md) {
		float: left;
		padding: 0 0.5em 0 1em;
		width: 50%;
	}
	@include media(sm) {
		float: none;
		padding: 0;
		width: 100%;
	}
}
.mobile,
.tablet {
	&.index{
		._black_button{
			&.mobile-only{
				display: block;
				font-size: 1.5rem;
			}
			&:not(.mobile-only){
				display: none;
			}
		}
	}
	.module,
	.module-inner {
		background: none;
		margin: 0.5em 0;

		& > .module-header {
			margin: 0 0 1em;
			background: $moduleMobileHeaderBg;
			border-radius: $borderRadius;

			&.toggle{
				position: relative;
				@include fontawesome('\f078') {
					font-size: 14px;
					color: $black;
					left: inherit;
					right: 1em;
					margin: -10px 0 0;
				}
				&.active{
					&:before{
						content: '\f077';
					}
				}
			}

			& > span {
				color: $moduleMobileHeaderColor;
				& > a {
					text-decoration: none;
					color: $moduleMobileHeaderColor;
				}

			}
			& > span,
			& > h1 {
				color: $black;
				// text-shadow: 1px 2px 1px #3c3c3c;
				a{
					color: $black;
				}
			}

			@include media(md) {
				padding: 0 1em;

				& > span,
				& > h1 {
					display: block;
					font-size: 20px;
					font-weight: 700;
					line-height: inherit;
					color: $black;
					// text-shadow: 1px 2px 1px #3c3c3c;
					margin: 0;
					padding: 0.3em 0;
					a{
						color: $black;
					}
				}
			}
		}

		.module-wrapper {
			padding: 0;
			margin: 0 0 1em;
		}
		.module-header{
			/*margin: 0 0 1em;*/
			&:after{
				display: none;
			}
		}
		&.gbook{
			display: block;
		}
		&.caller{
			// margin: 0 -0.5em;
		}
		.time-fast{
			border-width: 0.5em;
		}
	}
	.module-inner {
		&.catalog{
			> .module-header > h1{
				@include media(xs) {
					font-size: 16px;
					padding: 0 1em;
				}
			}
			&.inline{
				._black_button{
					@include media(xs) {
						display: none;
					}
				}
				.module-header{
					position: relative;
					border-radius: 0;
					&:after{
						display: block;
						content: '';
						position: absolute;
						left: -1em;
						top: 0;
						width: calc(100% + 2em);
						height: 100%;
						background: $moduleHeaderBg;
						z-index: 0;
					}
					h1{
						position: relative;
						z-index: 1;
						margin: 0;
					}
				}
			}
		}
		&.answers{
			.module-header{
				position: relative;
				border-radius: 0;
				margin: 0;
				&:before,
				&:after{
					display: block;
					content: '';
					position: absolute;
					top: 0;
					width: 1em;
					height: 100%;
					background: $moduleHeaderBg;
					z-index: 0;
				}
				&:before{
					left: -1em;
				}
				&:after{
					right: -1em;
				}
			}
		}
		&.gbook{
			.module_mid{
				.news{
					padding: 0;
					.news_text{
						&.hide{
							&:after{
								background-image: linear-gradient(to bottom,rgba(255,255,255,0),$moduleHeaderBg 75%);
							}
						}
					}
				}
			}
			.module-header{
				// background: none;
				position: relative;
				border-radius: 0;
				margin: 0;
				&:before,
				&:after{
					content: '';
					position: absolute;
					top: 0;
					width: 1em;
					height: 100%;
					background: $moduleHeaderBg;
					z-index: 0;
				}
				&:before{
					left: -1em;
				}
				&:after{
					display: block;
					right: -1em;
				}
			}
			.module-wrapper{
				position: relative;
				padding: 1em;
				background: $moduleHeaderBg;
				&:before,
				&:after{
					content: '';
					position: absolute;
					top: 0;
					width: 1em;
					height: 100%;
					background: $moduleHeaderBg;
					z-index: 0;
				}
				&:before{
					left: -1em;
				}
				&:after{
					right: -1em;
				}
			}
		}
		&.leave-request{
			padding: 1em;
			// margin: 0 -0.5em;
			border-width: 0.5em;
			.module-header{
				// background: none;
				// padding: 0;
				span{
					b{
						font-size: 11px;
					}
				}
			}
		}
		&.special{
			.module-header{
				border-radius: 0;
				position: relative;
				&:before,
				&:after{
					content: '';
					position: absolute;
					top: 0;
					width: 1em;
					height: 100%;
					background: $moduleHeaderBg;
					z-index: 0;
				}
				&:before{
					left: -1em;
				}
				&:after{
					display: block;
					right: -1em;
				}
			}
		}
	}
	.module{
		&.catalog{
			@include media(lg) {
				display: none;
			}
		}
	}
	.main-left-menu{
		margin: 0 0 1em;
		padding: 0 1em;
		.module-header{
			margin: 0;
		}
		.module-wrapper {
			@include media(md) {
				display: none;
			}
		}
	}
	.catalog{
		&.inline{
			._black_button{
				    top: 5px;
				    font-size: 1rem;
				    margin-bottom: 1em;
				    @include media(lg) {
				    	top: 0;
				    	font-size: 16px;
				    	margin-bottom: 0;
				    	padding: 0 5px;
				    }
				    @include media(md) {
				    	position: relative !important;
				    	right: unset !important;
				    	top: unset;
				    	vertical-align: text-bottom;
				    }
				}
			}
		}
	}

.instagram{
	width: 202px;
	margin: 1em 0 0;
}
.period-warranty{
	.module-header{
		border-radius: 0 !important;
		background: none !important;
		border-bottom: 1px solid #f2f2f2;
		margin: 0 0 2em;
		@include media(md) {
			border-bottom: none;
		}
	}
	.time__item{
		display: flex;
		align-items: center;
		justify-content: center;
		@include media(md) {
			justify-content: start;
			padding: 0 0 1em;
		}
		.time__img{
			padding-right: 30px;
			img {
				min-width: 80px;
				height: 80px;
			}
		}
		.time-title {
			font-size: 18px;
			font-weight: bold;
			text-transform: uppercase;
			@include media(md) {
				text-transform: none;
			}
			span {
				color: $linkColor;
				text-transform: none;
			}
		}
	}
	.time-txt {
		margin: 30px auto;
		text-align: center;
		max-width: 780px;
	}
}

.time-fast{
	padding: 70px;
	@include media(lg){
		padding: 1em;
	}
	border: 10px solid #f2f2f2;
	.time-wrapp {
		margin: 0 auto;
		max-width: 780px;
		text-align: center;
	}
	span {
		font-size: 24px;
		@include media(lg){
			font-size: 20px;
			font-weight: 700;
		}
		a{
			text-decoration: none;
		}
	}
}

.maincontacts{
	@include media(md){
		position: relative;
		&:before,
		&:after{
			content: '';
			position: absolute;
			top: 0;
			width: 1em;
			height: 100%;
			background: $moduleHeaderBg;
			z-index: 0;
		}
		&:before{
			left: -1em;
		}
		&:after{
			right: -1em;
		}
	}
	.row{
		align-items: flex-end;
	}
	.h2{
		font-weight: 500;
		font-size: 30px;
		text-align: center;
		margin: 0 0 25px;
		@include media(lg){
			font-size: 20px;
		}
	}
	.left,
	.right,
	.center{
		a{
			display: block;
			font-size: 24px;
			text-decoration: none;
			color: $textColor;
			@include media(lg){
				font-size: 16px;
			}
		}
		span {
			display: block;
		}
	}
	.left{
		text-align: right;
		@include media(lg){
			text-align: center;
		}
	}
	.right {
		text-align: left;
		@include media(lg){
			text-align: center;
		}
	}
	.center{
		text-align: center;
		a{
			font-size: 40px;
			@include media(lg){
				font-size: 16px;
			}
		}
	}
	.people-head{
		overflow: hidden;
		text-align: center;
		position: relative;
		.h2{
			display: inline-block;
			margin: 0;
			&:before,
			&:after {
				content: '';
				position: absolute;
				left: 0;
				top: 50%;
				display: block;
				width: 100%;
				border-bottom: 1px solid #d2d2d2;
			}
			span{
				position: relative;
				background: $white;
				padding: 0 1em;
				z-index: 1;
			}
		}
	}
	.people-item{
		margin: 3em 0;
		text-align: center;
		@include media(md){
			margin: 1em 0;
		}
		.people-pic {
			margin-bottom: 25px;
			img{
				border-radius: 50%;
				width: 200px;
			}
		}
		.people-name {
			margin:0 0 10px;
			font-size: 20px;
			font-weight: 700;
		}
		.people-content{
			p {
				margin: 0;
				padding: 0;
			}
			a{
				color: $textColor;
				text-decoration: none;
			}
		}
	}
}

.shipped-gifts{
	margin: 3em 0;
	.h2 {
		font-weight: 500;
		font-size: 30px;
		text-align: center;
		line-height: 35px;
		@include media(lg){
			font-size: 20px;
			font-weight: 700;
		}
		span{
			color: $linkColor;
			font-weight: 700;
			text-transform: none;
		}
	}
	.map{
		text-align: center;
	}
}

.help-kids{
	.h2 {
		font-weight: 500;
		font-size: 30px;
		text-align: center;
		line-height: 35px;
		@include media(lg){
			font-size: 20px;
			font-weight: 700;
		}
	}
}

.leave-request{
	padding: 30px 30px;
	border: 10px solid #f2f2f2;
	.module-header{
		padding: .3em .5em 3em;
		span{
			text-transform: none;
			font-weight: 500;
			font-size: 30px;
			text-align: center;
			margin: 0 0 25px;
			@include media(lg){
				font-size: 18px;
			}
			b{
				font-size: 14px;
				line-height: 22px;
				display: block;
				font-weight: 500;
				max-width: 400px;
				margin: 0 auto;
			}
		}
	}
	.module-wrapper{
	}
	.feedback-form{
		display: flex;
		flex-wrap: wrap;
		max-width: 800px;
		margin: 0 auto;
		&:before,
		&:after{
			display: none;
		}
		.feedback-block__title{
		}
		.feedback-col-6{
			flex: 0 0 100%;
			max-width: 100%;
			padding: 0;
			&.last{
				display: flex;
				flex-wrap: wrap;
				order: -1;
				padding: 0;
				.feedback-block{
					flex: 0 0 33.33333%;
					max-width: 33.33333%;
					@include media(md){
						flex: 0 0 100%;
						max-width: 100%;
					}
					&:nth-child(2){
						padding: 0 1em;
						@include media(md){
							padding: 0;
						}
					}
				}
			}
		}
	}
}

.benefits-item{
	.item{
		display: flex;
		align-items: center;
		justify-content: center;
		.item__title{
			font-size: 20px;
			text-align: center;
		}
	}
}

.editable_field{
	.faq{
		padding: 1em 0;
		background: #efefef;
		border-radius: 5px;
		box-shadow: 0 2px 3px grey;
		@include media(md){
			background: $moduleHeaderBg;
			position: relative;
			box-shadow: none;
			border-radius: 0;
			&:before,
			&:after{
				content: '';
				position: absolute;
				top: 0;
				width: 1em;
				height: 100%;
				background: $moduleHeaderBg;
				z-index: 0;
			}
			&:before{
				left: -1em;
			}
			&:after{
				right: -1em;
			}
		}
		.news_all{
			padding: 1em 0 0;
		}
		.news{
			border-bottom: 1px solid $white;
			padding: 0 1em;
			@include media(md){
			}
			.news_title{
				display: flex;
				align-items: center;
				justify-content: space-between;
				font-size: 24px;
				line-height: 1.35;
				padding: 0.5em 0;
				cursor: pointer;
				@include media(md){
					font-size: 18px;
				}
				.icon{
					display: flex;
					align-items: center;
					justify-content: center;
					position: relative;
					width: 50px;
					min-width: 50px;
					height: 50px;
					border-radius: 50%;
					background: $white;
					margin: 0;
					cursor: pointer;
					&:before{
						font-size: 24px;
						content: '+';
						position: absolute;
						color: $linkColor;
					}
				}
			}
			.news_text{
				font-size: 18px;
				background: $white;
				border-radius: 5px;
				width: 100% !important;
				@include media(md){
					font-size: 16px;
				}
				span{
					display: block;
					padding: 1em;
				}
				&.hide{
					display: none;
				}
			}
			&.active{
				border-color: transparent;
				.news_text{
				}
				.news_title{
					.icon{
						&:before{
							content: '-';
						}
					}
				}
			}
		}
	}
}

.ya_reviews{
	&_title{
		font-size: 30px;
		font-weight: 700;
		@include media(md){
			font-size: 20px;
		}
	}
	&_text{
		font-size: 22px;
		@include media(md){
			font-size: 18px;
		}
	}
	.ya_review_block{
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		padding: 0 1em;
		@include media(md){
			margin: 1em 0 0;
			padding: 1em;
		}
		a{
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			text-decoration: none;
		}
		.scores{
			color: $black;
			font-size: 28px;
			font-weight: 700;
			padding: 3px 0.5em 0;
			@include media(md){
				font-size: 20px;
			}
		}
		.ratings_block{
			img{
				width: 25px;
				height: 25px;
				@include media(md){
					width: 20px;
					height: 20px;
				}
			}
			.ratings_text{
				display: block;
			}
		}
	}
	.advantages{
		margin: 1em 0 0;
		@include media(md){
		}
		p{
			color: #222222;
			font-size: 14px;
			line-height: 1.55;
			font-weight: 400;
			border-radius: 30px;
			background: #f7f3ed;
			display: inline-block;
			padding: 0.5em 1em;
			margin: 0 1em 1em 0;
		}
	}
	#vk_widget{
		max-width: 230px;
		@include media(md){
			margin: 0 auto;
		}
	}
}
.yandex_review{
	margin: 0;
	&_title{
		font-size: 30px;
		font-weight: 700;
		@include media(md){
			font-size: 20px;
		}
	}
	&_text{
		font-size: 30px;
		@include media(md){
			font-size: 20px;
		}
	}
	img{
		width: 120px;
	}
	.img{
		font-size: 30px;
		font-weight: 700;
		color: $black;
		b,
		strong{
			color: #fc3f1d;
		}
	}
	.ya_review_block{
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
		padding: 0 1em;
		border-radius: 10px;
		background: #fafafa;
		@include media(md){
			margin: 1em 0 0;
			padding: 1em 0;
		}
		a{
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			text-decoration: none;
		}
		.scores{
			color: $black;
			font-size: 28px;
			font-weight: 700;
			padding: 3px 0.5em 0;
			@include media(md){
				font-size: 20px;
			}
		}
		.ratings_block{
			img{
				width: 25px;
				height: 25px;
				@include media(md){
					width: 20px;
					height: 20px;
				}
			}
			.ratings_text{
				font-size: 16px;
				display: block;
				text-align: center;
			}
		}
	}
}
.photo_review{
	.owl-item{
		padding: 0.5em 0;
		text-align: center;
	}
	img{
		width: 100%;
		max-width: 300px;
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
		border-radius: 10px;
		transform: scale(0.9);
		transition: transform 0.3s ease;
		&:hover {
			transform: scale(1);
		}
	}
}
.module-inner{
	.video_review{
	padding: 0;
}
}
.video_review{
    padding: 0 1em;
	iframe,
	video{
		width: 100%;
		height: 150px;
		border-radius: 5px;
		background: #000;
		// object-fit: cover;
		// object-position: 50% 30%;
		@include media(md) {
		}
	}
}
.video_widget{
    // display: flex;
    // align-items: center;
    // justify-content: center;
	// width: 320px;
	// height: 220px;
	// position: relative;
	margin: 0;
	// overflow: hidden;
	.module-header{
		display: none;
	}
	@include media(xs) {
	.module-header{
		display: block;
	}
		// width: 300px;
	}
	.module-wrapper {
		// position: relative;
		// width: 100%;
		// height: 100%;
	}
	.fixed-video {
		position: fixed;
		top: 75px;
		z-index: 999;

	}
	.close-button {
		display: none;
		position: fixed;
		top: 75px;
		background: none;
		color: white;
		border: none;
		cursor: pointer;
		border-radius: 5px;
		z-index: 999;
		margin: 0 0 0 280px;
		box-shadow: none;
		font-size: 20px;
	}
	p{
		padding: 0;
		// width: 320px;
		// height: 220px;
		@include media(xs) {
			width: 100%;
			// max-width: 280px;
			margin: 0 auto;
		}
	}
	iframe,
	video{
		width: 100%;
		height: 100%;
		max-width: 350px;
    	min-height: 200px;
		max-height: 320px;
		// position: absolute;
		// left: 0;
		// top: 0;
		// width: 100%;
		// height: 100%;
		// object-fit: cover;
	}
}

.guarantee_quality{
	.item{
		text-align: center;
	}
	.item-image{
		line-height: 0;
		margin-bottom: 40px;
		img {
			border: 0 none;
			margin: 0 !important;
			height: auto;
		}
	}
	.title{
		font-size: 16px;
		margin-bottom: 20px;
	}
	.text{
	}
	.button-link{
		text-align: center;
	.button{
		background: linear-gradient(to top, $borderColor, #999);
		&:hover{
			transition: none;
		    background: #FF7F50;
		}
	}
	}
}


.trust_us{
	background: #efefef;
	.item {
		margin-bottom: 43px;
		min-height: 160px;
		.item_img{
			float: left;
			width: 30px;
			margin-right: 10px;
			img {
				width: 24px;
			}
		}
		.item_text {
			overflow: hidden;
			.item_title {
				margin-bottom: 12px;
				font-size: 16px;
				font-weight: bold;
			}
			.item_info{
				p{
					padding: 0;
				}
			}
		}
	}
	.button-link{
		margin-bottom: 43px;
		text-align: center;
	}
}

div[id^="vk_groups_vk_widget_"] {
    margin: 0 auto;
}

.useful-articles{
	.item{
		.item-image{
			margin: 0 auto;
			display: block;
		}
		.title{
			a{
				text-decoration: none;
			}
		}
		.text{
			margin-bottom: 5px;
		}
	}
}

._black_button {
	text-align: center;
	background: transparent;
	box-shadow: 0 0px 1px grey,0 0px 1px grey;
	border: 1px solid #f2f2f2;
	border-radius: 4px;
	display: inline-block;
	color: #000;
	font-size: 1.5rem;
	cursor: pointer;
	padding: 4px 14px;
	pointer-events: all;
	text-decoration: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-tap-highlight-color: rgba(0,0,0,.15);
	-webkit-appearance: none;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	-webkit-font-smoothing: antialiased;
	outline: 0;
	/*text-transform: uppercase;*/
	transition: all .3s ease;
	@include media(md) {
		font-size: 1rem;
	    // margin-bottom: 1em;
	}
}

.download-catalog{
	position: relative;
	display: block;
	padding: 1em 0 0;
	&:hover{
		img{
			opacity: 0;
			&.hover{
				opacity: 1;
			}
		}
	}
	img{
		transition: opacity 0.3s ease;
		&.hover{
			position: absolute;
			left: 0;
			transition: opacity 0.3s ease;
			opacity: 0;
		}
	}
}


.plyr audio, .plyr iframe, .plyr video{
	@include media(md) {
		max-width: unset;
		max-height: unset;
	}
}