/* Список подразделов, плитки по 3 колонки */
.category-list {
  @extend .clearfix;

  .category-list__item {
    border: 1px solid #dfdfe0;
    cursor: pointer;
    float: left;
    position: relative;
    margin: 0 1em 2em 0;
    width: calc(16.666% - .9em);
    transition: all 0.3s ease;
    border-radius: $borderRadius;
    overflow: hidden;
    -webkit-tap-highlight-color: $webkitHighlightTapColor;
    padding: 1em;
    > a {
      font-size: 16px;
      display: block;
      width: 100%;
      text-decoration: none;
      text-transform: uppercase;
      text-align: center;
      border: 1px solid transparent;

      @include media(xlg) {
        padding: 1em;
        font-size: 15px;
      }

      @include media(lg) {
        font-size: 14px;
      }
      @include media(xmd) {
        font-weight: 700;
        max-width: 215px;
        margin: 0 auto;
      }
      &:hover{
        .category-list__item-image {
          @extend .animated;
          @extend .pulse;
        }
        .category-list__item-title{
        }
      }
    }
    .category-list__item-title {
      flex: 1;
      display: block;
    }
    &:nth-child(6n + 6) {
      margin: 0 0 2em 0;
    }


    @include media(lg) {
      width: calc(25% - .75em);

      &:nth-child(6n + 6) {
        margin: 0 1em 1em 0;
      }


      &:nth-child(4n + 4) {
        margin: 0 0 2em 0;
      }
    }

    @include media(md) {
      margin: 0 1em 1em 0;
      width: calc(33.333% - 1em);

      &:nth-child(2n + 2) {
        margin: 0 1em 1em 0;
      }


      &:nth-child(3n + 3) {
        margin: 0 0 1em 0;
      }
    }
    @include media(xmd) {
      width: calc(50% - 0.5em);
      border: 1px solid #f2f2f2;
      &:last-child{
        margin: 0 auto;
        float: none;
      }

      &:nth-child(3n + 3) {
        margin: 0 1em 1em 0;
      }


      &:nth-child(2n + 2) {
        margin: 0 0 1em 0;
      }
    }



    @include media(xs) {
// margin: 0 0 1em;
// float: none;
// width: 100%;

&:nth-child(3n + 3) {
// margin: 0 0 1em 0;
}
}

}
.category-list__item-image {
  margin: 0 auto 1em;
  transform: scale3d(0.9, 0.9, 1);
  @extend .img-responsive;
  @include media(lg) {
    border-radius: $borderRadius;
  }
}
&.hidden {
  display: none;
}
}

.mobile,
.tablet {
  .module-inner{
    &.catalog{
      .category-list{
        .category-list__item{
          &:last-child{
            width: 100%;
            margin: 0;
            height: auto !important;
            > a{
              padding: 0 1em;
              max-width: 100%;
              display: flex;
              align-items: center;
              // justify-content: space-between;
              .category-list__item-image{
                max-width: 147px;
                margin: 0 auto;
                order: 1;
              }
            }
          }
        }
      }
    }
  }
}

.module-inner{
  &.catalog{
    &.inline{
      .category-list{
        .category-list__item{
          @include media(md) {
            width: 100%;
            height: auto !important;
          }
          > a{
            @include media(md) {
              padding: 0 1em;
              max-width: 100%;
              display: flex;
              align-items: center;
            }
            .category-list__item-image{
              @include media(md) {
                max-width: 147px;
                margin: 0;
              }
            }
          }
          &:last-child{
            justify-content: flex-start;
            > a{
              .category-list__item-image{
                order: 0;
                margin: 0 0 1em;
              }
            }
          }
        }
      }   
    }
  }
}

.catalog{
  &.inline{
    &.box{
      .category-list{
        .category-list__item{
          cursor: default;
          // padding: 40px 10px;
          box-sizing: border-box;
          text-align: center;
          border: 1px solid #dcdcdc;
          &:hover {
            border-color: transparent;
            box-shadow: 0 0 20px 0 rgba(0,0,0, 0.2);
          }
          a{
            padding: 0;
            &:before{
              content: '';
              width: 80px;
              height: 80px;
              background: #fc6044 url(/data/team/gift-box.png) 0 0 no-repeat;
              background-size: contain;
              display: block;
              margin: 0 auto;
              border-radius: 50%;
            }
          }
          &:nth-child(2){
            a{
              &:before{
                background: #fc6044 url(/data/team/gift-box-2.png) 0 0 no-repeat;
                background-size: contain;
              }
            }
          }
          &:nth-child(3){
            a{
              &:before{
                background: #fc6044 url(/data/team/gift-box-3.png) 0 0 no-repeat;
                background-size: contain;
              }
            }
          }
          &:nth-child(4){
            a{
              &:before{
                background: #fc6044 url(/data/team/gift-box-4.png) 0 0 no-repeat;
                background-size: contain;
              }
            }
          }
          &:nth-child(5){
            a{
              &:before{
                background: #fc6044 url(/data/team/gift-box-5.png) 0 0 no-repeat;
                background-size: contain;
              }
            }
          }
          .category-list__item-image{
            display: none;
          }
          .category-list__item-title{
            padding: 1em 1em 0;
          }
          > a{
            justify-content: center;
            padding: 1em 0 0;
          }
        }
      }
    }
  }
}